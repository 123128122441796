import {userConstants} from '../constants';
import {indexdb, store} from "../helpers";
import {userActions} from "../actions";


indexdb.get('auth').then(result => {
    if(result){
        store.dispatch(userActions.success(result));
    }
});

let auth = null;

const initialState = auth ? {logged: true, loggingIn: false, auth: auth} : {logged: false, loggingIn: false, auth:{}};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                logged: false,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggingIn: false,
                logged: true,
                auth: action.auth
            };
        case userConstants.LOGIN_FAILURE:
            return {...state, loggingIn: false};
        case userConstants.LOGOUT:
            return {...state, logged: false, auth: {}};
        default:
            return state
    }
}
