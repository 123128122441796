/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {LoginCont} from "../containers/LoginCont";
import Header from "../components/Headers/Header";

class Login extends React.Component {
    render() {
        return (
            <>
                <Header title="Login" subtitle="time selecionado" gradient="info" />
                {/* Page content */}
                <Container className="mt--7 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7" className="shadow mb-2">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-5">
                                    <div className="text-muted text-center mt-2 mb-3">
                                        <h2>Login</h2>
                                    </div>
                                </CardHeader>
                                <CardBody className="px-lg-5 py-lg-5">
                                    <LoginCont/>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <a
                                        className="text-orange"
                                        href="/accounts/password/reset/"
                                    >
                                        <small>Esqueceu a senha?</small>
                                    </a>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <Link
                                        className="text-orange"
                                        to="/cadastro">
                                        <small>Criar nova conta</small>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Login;
