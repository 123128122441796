import {usePromiseTracker} from "react-promise-tracker";
import LoadingOverlay from "react-loading-overlay";
import React from "react";

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
  return (
      promiseInProgress &&
        <LoadingOverlay
              active={true}
              spinner
              text='Carregando...'>
        </LoadingOverlay>
 );
};

export default LoadingIndicator;
