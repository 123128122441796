import React from "react";
import {Redirect} from 'react-router';
// reactstrap components
import {Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {toast} from "react-toastify";
import {trackPromise} from "react-promise-tracker";
import {userActions} from "../actions";
import {connect} from "react-redux";

class LoginCont extends React.Component {
       constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            access_token: '',
            provider:''
        };
    }

    handleSocialLogin = (user) => {
        const at = user._token.accessToken;
        const pvd = user._provider;
        this.setState({access_token: at, provider: pvd});
        if (at) {
            trackPromise(this.props.loginSocial(at, pvd));
        }
    };

    handleSocialLoginFailure = (err) => {
        const at = this.state.access_token;
        const pvd = this.state.provider;
        if (at) {
            trackPromise(this.props.loginSocial(at, pvd));
        }
    };

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleSubmit =  event => {
        event.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;

        if(!username){
            toast.error("Email é obrigatório");
        }
        if(!password){
            toast.error("Senha é obrigatório");
        }

        if (username && password) {
            trackPromise(this.props.login(username, password));
        }
    };
    render() {
        const { loggingIn, logged } = this.props;
        const { username, password } = this.state;
        return (
            <>
                <Form role="form" onSubmit={this.handleSubmit}>
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-email-83"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Email" value={username} name="username" type="email" autoComplete="new-email" onChange={this.handleChange} required/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-lock-circle-open"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Senha" value={password} name="password" type="password" autoComplete="new-password" onChange={this.handleChange} required/>
                        </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                        <a href={'/accounts/password/reset/'} >Esqueceu a senha?</a>
                    </div>
                    <div className="text-center">
                        <Button className="my-4" color="primary" type="submit">
                            Entrar
                        </Button>
                         {/*<SocialButton*/}
                         {/*           provider='facebook'*/}
                         {/*           name='Facebook'*/}
                         {/*           appId='641985273118434'*/}
                         {/*           onLoginSuccess={this.handleSocialLogin}*/}
                         {/*           onLoginFailure={this.handleSocialLoginFailure}>*/}
                         {/*</SocialButton>*/}
                        {logged && <Redirect to='/index'/>}
                    </div>
                </Form>
            </>
        );
    }
}

function mapStateToProps(reducer) {
    const { loggingIn, logged } = reducer.authentication;
    return { loggingIn, logged };
}

const actionsToProps = {
    login: userActions.login,
    loginSocial: userActions.loginSocial,
    logout: userActions.logout
};

const connLoginCont = connect(mapStateToProps,actionsToProps)(LoginCont);
export { connLoginCont as LoginCont};
