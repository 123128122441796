import React, {Component} from "react";
// reactstrap components
import {Container} from "reactstrap";
// core components
import PageHeader from "../components/Headers/PageHeader.js";
import ClubsCont from "../containers/ClubsCont";
import Header from "../components/Headers/Header";


export default class Classification extends Component {
  render() {
    return (
      <>
        <Header title="Classificação" subtitle="classificação geral dos times na rodada" gradient="info" />
        {/* Page content */}
        <Container className="mt--7" >
          <ClubsCont />
        </Container>
      </>
    );
  }
}
