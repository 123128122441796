import axios from 'axios';
import {store} from './store';
import {userConstants} from "../constants";
import {userActions} from "../actions";
import {indexdb} from "./indexdb";

export default class AuthService {
    init = () => {
        this.setInterceptors();
    };

    setInterceptors = () => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + getToken();
    };

}

export const security = {
    setLogin,
    loggedIn,
    getLoggedUser,
    getRefreshToken,
    setRefreshToken,
    setLogout,
    getAuthHeader,
}

function setLogin(data) {
    indexdb.set('auth', data);
}

function setLogout() {
    indexdb.remove('auth');
}

function loggedIn() {
    const state = store.getState();
    return state.authentication.logged;
}

function getLoggedUser() {
    const state = store.getState();
    const auth = state.authentication.auth;
    if (auth && auth.user && auth.access) {
        return auth.user;
    } else {
        return {};
    }
}

function getToken() {
    const state = store.getState();
    const auth = state.authentication.auth;
    if (auth && auth.user && auth.access) {
        return auth.access;
    } else {
        return '';
    }
}

function getRefreshToken() {
    const state = store.getState();
    const auth = state.authentication.auth;
    if (auth && auth.user && auth.refresh) {
        return auth.refresh;
    } else {
        return '';
    }
}

function setRefreshToken(data) {
    const state = store.getState();
    const auth = state.authentication.auth;
    let new_auth = {...auth, access: data.access};
    indexdb.set('auth', new_auth);
}

function getAuthHeader(){
    const token = getToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
    return headers;
}


axios.interceptors.response.use(response => {
    return response;
}, err => {
    return new Promise((resolve, reject) => {
        const originalReq = err.config;
        //&& !err.config.__isRetryRequest
        if (err.response && err.response.status === 401 && err.config && err.response.data && err.response.data.code == 'token_not_valid') {
            originalReq._retry = true;
            let res = fetch(axios.defaults.baseURL+'login/refresh', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refresh: getRefreshToken()
                }),
            }).then(res => res.json()).then(res => {
                const state = store.getState();
                let auth = state.authentication.auth;

                if(res.code && res.code=='token_not_valid'){
                    store.dispatch(userActions.logout());
                }else{
                    setRefreshToken(res);
                    auth.access = res.access;
                    store.dispatch(userActions.success(auth));
                    originalReq.headers['Authorization'] = 'Bearer ' + res.access;
                    return axios(originalReq);
                }
            });

            resolve(res);
        }

        return reject(err);
    });
});


