export const cartolaConstants = {
    FETCH_MERCADO_SUCCESS: 'FETCH_MERCADO_SUCCESS',
    FETCH_MERCADO_FAILED: 'FETCH_MERCADO_FAILED',

    ESCALAR_START: 'ESCALAR_START',
    ESCALAR_RESET: 'ESCALAR_RESET',
    ESCALAR_SUCCESS: 'ESCALAR_SUCCESS',
    ESCALAR_FAILED: 'ESCALAR_FAILED',

    OLHEIRO_START: 'OLHEIRO_START',
    OLHEIRO_RESET: 'OLHEIRO_RESET',
    OLHEIRO_SUCCESS: 'OLHEIRO_SUCCESS',
    OLHEIRO_FAILED: 'OLHEIRO_FAILED',

    PARCIAL_START: 'OLHEIRO_START',
    PARCIAL_RESET: 'OLHEIRO_RESET',
    PARCIAL_SUCCESS: 'OLHEIRO_SUCCESS',
    PARCIAL_FAILED: 'OLHEIRO_FAILED',
};
