import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {Badge, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row} from "reactstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
// core components
import Header from "components/Headers/Header.js";
import ClubList from "../components/ClubList";
import Slider from "../components/Slider";
import {connect} from "react-redux";
import FormationList from "../components/FormationList";
import {cartolaActions} from "../actions";
import {trackPromise} from "react-promise-tracker";
import {Redirect} from 'react-router';
import ClubFilter from "../components/ClubFilter";
import ShareButton from "../components/ShareButton";


class Machine extends React.Component {
    state = {
        cartoleta: 100,
        modo: "off",
        modocart: "off",
        atas: 1,
        meis: 1,
        lats: 1,
        zags: 1,
        gols: 1,
        tecs: 1,
        ipj: 7,
        ipp: 4,
        ipt: 2,
        rodadas: 10,
        rodada_id: 1,
        times_modo: 'T',
        times: [],
        times_selected: [],
        formacoes: [],
        formacoes_selected: [],
    };

    resetState = JSON.parse(JSON.stringify(this.state));


    handleModo = (checked) => {
        if (!checked) {
            this.setState({modo: 'on'});
        } else {
            this.setState({modo: 'off'});
        }
    };

    handleModocart = (checked) => {
        if (!checked) {
            this.setState({modocart: 'on'});
        } else {
            this.setState({modocart: 'off'});
        }
    };

    timesModoSelect(times_modo){
        const times = this.props.times;
        const partidas = this.props.partidas;
        let selected_times = [];
        if(times){
            if(times_modo=='T'){
                for(let t of times){
                    selected_times.push(t.id);
                }
            }else if(times_modo=='C'){
                for(let p of partidas){
                    selected_times.push(p.time_casa);
                }
            }else if(times_modo=='V'){
                for(let p of partidas){
                    selected_times.push(p.time_visitante);
                }
            }else if(times_modo=='R'){
                for(let p of partidas){
                    if(p.ordem<=5){
                        if(p.bhome<=p.baway){
                            selected_times.push(p.time_casa);
                        }else{
                            selected_times.push(p.time_visitante);
                        }
                    }
                }
            }
        }
        this.setState({times_modo, times: selected_times})
    }

    setTimesModo = (event) => {
        const times_modo = event.target.value;
        this.timesModoSelect(times_modo);
    };

    componentDidMount(){
        const times_modo = this.state.times_modo;
        this.timesModoSelect(times_modo);

        if(this.props.config){
            this.setState(this.props.config);
            this.setState({times_selected: this.props.config.times});
            this.setState({formacoes_selected: this.props.config.formacoes});
        }else{
            this.setState({formacoes: [1,2,3,4,5,6,7]});
            this.setState({formacoes_selected: [1,2,3,4,5,6,7]});
        }

    }

    onChangeTimes = (times) => {
        this.setState({times_selected: times});
    };

    onChangeFormations = (formations) => {
        this.setState({formacoes_selected: formations});
    };

    onChangeSlider = (e, item) => {
        const { value } = e.target;
        this.setState({[item]:value});
    };

    onChangeInput = event => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({[name]: value});
    };

    resetConfig = (e) => {
        this.setState(this.resetState);
        this.setState({cartoleta: this.state.cartoleta});
        this.setState({formacoes: [1,2,3,4,5,6,7]});
        const times_modo = this.resetState.times_modo;
        this.timesModoSelect(times_modo)
    };

    submitConfig = (e) => {
        e.preventDefault();

        let config = {
            cartoleta: this.state.cartoleta,
            modo: this.state.modo,
            modocart: this.state.modocart,
            atas: this.state.atas,
            meis: this.state.meis,
            lats: this.state.lats,
            zags: this.state.zags,
            gols: this.state.gols,
            tecs: this.state.tecs,
            ipj: this.state.ipj,
            ipp: this.state.ipp,
            ipt: this.state.ipt,
            rodadas: this.state.rodadas,
            times: this.state.times_selected,
            formacoes: this.state.formacoes_selected,
        };

        trackPromise(this.props.fetchEscalacao(config));
        this.props.history.push("/time");

    };


    render() {
        const { time } = this.props;
        return (
            <>
                <Header title="Escalação" subtitle="escale seu time" gradient="default"/>
                {/* Page content */}
                <Container className=" mt--7">
                    {/* Table */}
                    <Row id={"rootDiv"}>
                        <div className=" col">
                            <Card className=" shadow mb-2">
                                <CardHeader className=" bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Configure a Máquina</Badge>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="row">
                                                <label
                                                    className="col-md-4 col-form-label"
                                                    htmlFor="input-cartoletas"
                                                >
                                                    Cartoletas($):
                                                </label>
                                                <Input
                                                    className="form-control col-md-8"
                                                    id="input-cartoletas"
                                                    name="cartoleta"
                                                    value={this.state.cartoleta}
                                                    onChange={this.onChangeInput}
                                                    pattern="[0-9]*"
                                                    type="number"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup className="row">
                                                <label
                                                    className="col-md-3 col-form-label text-sm-right"
                                                    htmlFor="modo-checkbox"
                                                >
                                                    Modo:
                                                </label>
                                                <BootstrapSwitchButton
                                                    id="modo-checkbox"
                                                    checked={this.state.modo == 'off'}
                                                    onlabel='Torcedor'
                                                    onstyle='info'
                                                    offlabel='Treinador'
                                                    offstyle='success'
                                                    width='200'
                                                    reversed
                                                    className='form-control-plaintext col-md-9 '
                                                    name="modo-checkbox"
                                                    onChange={this.handleModo}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="row">
                                                <label
                                                    className="col-md-4 col-form-label"
                                                    htmlFor="input-cartoletas"
                                                >
                                                    Analisar:
                                                </label>
                                                <select id="rodadas" name="rodadas"  onChange={this.onChangeInput} value={this.state.rodadas}
                                                        className="form-control col-md-8 btn-lg">
                                                    <option value="50" >TODO CAMPEONATO</option>
                                                    <option value="20">MELHORES DE 20 RODADAS</option>
                                                    <option value="10">MELHORES DE 10 RODADAS</option>
                                                    <option value="8">MELHORES DE 8 RODADAS</option>
                                                    <option value="5">MELHORES DO MÊS</option>
                                                    <option value="3">MELHORES DA SEMANA</option>
                                                    <option value="1">ÚLTIMA RODADA</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup className="row">
                                                <label
                                                    className="col-md-3 col-form-label text-sm-right"
                                                    htmlFor="modo-checkbox"
                                                >
                                                    Objetivo:
                                                </label>
                                                <BootstrapSwitchButton
                                                    key="modo-cartoleta"
                                                    id="modo-cartoleta"
                                                    checked={this.state.modocart == 'off'}
                                                    onlabel='Pontos'
                                                    onstyle='info'
                                                    offlabel='Cartoletas'
                                                    offstyle='success'
                                                    width='200'
                                                    className='form-control-plaintext col-md-9 '
                                                    name="modo-cartoleta"
                                                    onChange={this.handleModocart}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className={"shadow mb-2 " + (this.state.modo == 'off' ? 'd-none' : '')}>
                                <CardHeader className="bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Peso do Jogador, Partida e
                                        Time</Badge>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Jogador:"
                                                id="ipj"
                                                value={this.state.ipj}
                                                onChange={this.onChangeSlider}
                                                max={10}
                                                min={0}
                                                ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                                ticks_snap_bounds={1}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Partida:"
                                                id="ipp"
                                                value={this.state.ipp}
                                                onChange={this.onChangeSlider}
                                                max={10}
                                                min={0}
                                                ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                                ticks_snap_bounds={1}
                                            >
                                                {/*<Button color="primary"*/}
                                                {/*        onClick={e => e.preventDefault()}*/}
                                                {/*        size="sm">Verificar Partidas</Button>*/}
                                            </Slider>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Time:"
                                                id="ipt"
                                                value={this.state.ipt}
                                                onChange={this.onChangeSlider}
                                                max={10}
                                                min={0}
                                                ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                                                ticks_snap_bounds={1}
                                            >
                                                {/*<Button color="primary"*/}
                                                {/*        onClick={e => e.preventDefault()}*/}
                                                {/*        size="sm">Verificar Times</Button>*/}
                                            </Slider>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className={"shadow mb-2 " + (this.state.modo == 'off' ? 'd-none' : '')}>
                                <CardHeader className="bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Escolha de 1 a 100 a força do
                                        setor</Badge>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Atacante:"
                                                id="atas"
                                                value={this.state.atas}
                                                onChange={this.onChangeSlider}
                                                dual="true"
                                                max={100}
                                                min={1}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Meia:"
                                                id="meis"
                                                value={this.state.meis}
                                                onChange={this.onChangeSlider}
                                                max={100}
                                                dual="true"
                                                min={1}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Lateral:"
                                                id="lats"
                                                value={this.state.lats}
                                                onChange={this.onChangeSlider}
                                                max={100}
                                                dual="true"
                                                min={1}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Zagueiro:"
                                                id="zags"
                                                value={this.state.zags}
                                                onChange={this.onChangeSlider}
                                                max={100}
                                                dual="true"
                                                min={1}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Goleiro:"
                                                id="gols"
                                                value={this.state.gols}
                                                onChange={this.onChangeSlider}
                                                max={100}
                                                dual="true"
                                                min={1}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Slider
                                                label="Técnico:"
                                                id="tecs"
                                                value={this.state.tecs}
                                                onChange={this.onChangeSlider}
                                                max={100}
                                                dual="true"
                                                min={1}
                                            />
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                            <Card className={"shadow mb-2 " + (this.state.modo == 'off' ? 'd-none' : '')}>
                                <CardHeader className="bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Escolha as formações</Badge>
                                </CardHeader>
                                <CardBody>
                                    <FormationList formations={this.props.formacoes} selected_formations={this.state.formacoes} onChange={this.onChangeFormations}/>
                                </CardBody>
                            </Card>
                            <Card className={"shadow mb-2 "}>
                                <CardHeader className="bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Escolha os times</Badge>
                                    <ClubFilter mode={this.state.times_modo} onChange={this.setTimesModo} />
                                </CardHeader>
                                <CardBody>
                                    <ClubList times={this.props.times} selected_clubs={this.state.times} onChange={this.onChangeTimes}/>
                                </CardBody>
                            </Card>

                            <div className="btn-group btn-group-justified " role="group" aria-label="...">
                                <div className="btn-group" role="group">
                                    <button type="button" id="limpar" className="btn btn-primary" onClick={this.resetConfig}>
                                        <span className="d-inline-flex">
                                        <i className="ni ni-fat-remove"></i><span
                                        className="d-none d-sm-block"> Limpar</span>
                                        </span>
                                    </button>
                                </div>
                                <div className="btn-group" role="group">
                                    <ShareButton idElement={"#rootDiv"} msgm={"Minha configuração na MáquinaDoCartola.com #Rodada"+this.state.rodada_id} />
                                </div>
                                <div className="btn-group" role="group">
                                    <button type="button" id="pesquisarTime"
                                            onClick={this.submitConfig}
                                            className="btn btn-primary ">
                                        <span className="d-inline-flex">
                                        <i className="fa fa-search"></i><span
                                        className="d-none d-sm-block"> Pesquisar</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Row>

                    { time && <Redirect to="/time" />}
                </Container>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { mercado } = state.loadMercado;
    const { time, config } = state.escalarMachine;
    const times = [];
    if(mercado){
        for (const [key, value] of Object.entries(mercado.times)) {
          times.push(value);
        }
    }

    return {
        times,
        time,
        config: config ? config:null,
        partidas: mercado? mercado.partidas:[],
        rodada_id: mercado ? mercado.rodada.codigo : '',
        formacoes: [
            {id: 1, value: "3-4-3"},
            {id: 2, value: "3-5-2"},
            {id: 3, value: "4-3-3"},
            {id: 4, value: "4-4-2"},
            {id: 5, value: "4-5-1"},
            {id: 6, value: "5-3-2"},
            {id: 7, value: "5-4-1"}],
    }
}

const actionCreatorsToProps = {
    fetchEscalacao: cartolaActions.fetchEscalacao
};

export default connect(mapStateToProps, actionCreatorsToProps)(Machine);
