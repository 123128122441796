import {cartolaConstants} from "../constants";
import {indexdb, store} from "../helpers";
import {cartolaActions, userActions} from "../actions";


indexdb.get('config').then( config => {
    if(config){
        store.dispatch(cartolaActions.requestEscalacao(config));
        indexdb.get('time').then( time => {
            store.dispatch(cartolaActions.successEscalacao(time));
        })
    }
});

const initialState = {};

export function escalarMachine(state = initialState, action) {
  switch (action.type) {
    case cartolaConstants.ESCALAR_START:
      return {
          ...state,
        config: action.config,
        searching: true,
      };
      case cartolaConstants.ESCALAR_SUCCESS:
      return {
          ...state,
          time: action.time,
          searching: false,
      };
      case cartolaConstants.ESCALAR_FAILED:
      return {
          ...state,
          error: action.error,
          searching: false,
      };
      case cartolaConstants.ESCALAR_RESET:
      return {
          ...state,
          time: null,
      };
    default:
      return state
  }
}
