/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useRef} from "react";
// reactstrap components
import {Button, Card, CardFooter, CardHeader, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import ModalDetail from "./Modals/ModalDetail";
import ModalHelp from "./Modals/ModalHelp";
import * as PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import html2canvas from 'html2canvas';
import ShareButton from "./ShareButton";


// core components

function BackButton(props) {
    let history = useHistory();

    function handleClick() {
        history.goBack();
    }

    return <button type="button" id="limpar" className="btn btn-primary" onClick={handleClick}>
        <span className="d-inline-flex">
        <i className="fa fa-backward"></i><span
            className="d-none d-sm-block"> Voltar</span></span>
    </button>;
}

function UpdateButton(props) {
    let history = useHistory();

    function handleClick() {
        history.go(0);
    }

    return <button type="button" id="update" className="btn btn-primary" onClick={handleClick}>
        <span className="d-inline-flex">
        <i className="fa fa-sync"></i><span
        className="d-none d-sm-block"> Atualizar</span>
        </span>
    </button>;
}

class ObjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            title: "",
            titleModal: "",
            helpModal: false,
            itemModal: false,
            shareModal: false,
            itemSelect: null,
            rodada_id: '',
        };

        this.canvas = React.createRef();
    }

    toggleModal = (e, state, id = null) => {
        e.preventDefault();
        //console.log(e.target);
        this.setState({
            [state]: !this.state[state], itemSelect: id
        });
    };

    getObjectItem = (item, index) => {
        return '';
    };

    getTitleList = () => {
        return '';
    };

    getHelpItem = () => {
        return '';
    };

    getHeaderList = () => {
        return '';
    };

    getFooterList = () => {
        return '';
    };

    getHelpModal = () => {
        return '';
    };

    getItemModal = () => {
        return '';
    };

    getHeaderModal = () => {
        return null;
    };

    back = (e) => {
        e.preventDefault();
    };

    buttonLeft = () => {
        return (<BackButton/>);
    };

    buttonCenter = () => {
        return (<ShareButton idElement={"#rootDiv"}/>);
    };


    buttonRight = () => {
        return (<UpdateButton/>);
    };

    async componentDidMount() {
        this.setState({helpModal: false, itemModal: false, shareModal: false, rodada_id: ''})
    };


    render() {
        const {itens} = this.state;
        const id = this.props.id ? this.props.id : 'id';
        return (
            <div ref={this.canvas}>
                <Card className="shadow" id={"rootDiv"}>
                    <CardHeader className="border-0">
                        <Row>
                            <Col xs="8" sm="8" md="10">
                                {this.getHeaderList()}
                            </Col>
                            <Col className="text-right" xs="4" sm="4" md="2">
                                {!this.getHelpItem() &&
                                <Button color="default" href="#bie" className="right"
                                        onClick={(e) => this.toggleModal(e, "helpModal")} size="sm">
                                    <i className="fa fa-question-circle"/> Ajuda
                                </Button>
                                }{this.getHelpItem() && this.getHelpItem()}
                            </Col>
                        </Row>
                    </CardHeader>
                    <ListGroup>
                        <ListGroupItem active tag="a" href="#" action>{this.getTitleList()}</ListGroupItem>
                        {
                            itens.map((item, index) => (
                                <ListGroupItem key={item[id]} className="p-lg-3 p-md-3 p-xs-1"
                                               onClick={(e) => this.toggleModal(e, "itemModal", item[id])} tag="a"
                                               href="#" action>{this.getObjectItem(item, index)}</ListGroupItem>
                            ))
                        }
                        {/*<ListGroupItem active tag="a" href="#" action>Cras justo odio</ListGroupItem>*/}
                    </ListGroup>
                    <CardFooter className="py-4">
                        <nav aria-label="..." className="text-sm">
                            {this.getFooterList()}
                        </nav>
                    </CardFooter>
                </Card>

                <div className="btn-group btn-group-justified " role="group" aria-label="...">
                    <div className="btn-group" role="group">
                        {this.buttonLeft()}
                    </div>
                    <div className="btn-group" role="group">
                        {this.buttonCenter()}
                    </div>
                    <div className="btn-group" role="group">
                        {this.buttonRight()}
                    </div>
                </div>

                {/* Modal  Help*/}
                <ModalHelp state="helpModal" title={this.state.title} isOpen={this.state.helpModal}
                           toggleModal={this.toggleModal}>
                    {this.getHelpModal()}
                </ModalHelp>

                {/* Modal Item  */}
                <ModalDetail state="itemModal" title={this.state.titleModal} isOpen={this.state.itemModal}
                             toggleModal={this.toggleModal} getHeader={this.getHeaderModal}>
                    {this.getItemModal()}
                </ModalDetail>

                <ModalHelp state="shareModal" title={"Compartilhar"} isOpen={this.state.shareModal}
                           toggleModal={this.toggleModal}>
                    <div id="shareDiv">
                    </div>
                </ModalHelp>
            </div>
        );
    }
}


export default ObjectList;

