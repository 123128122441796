import React from "react";
// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import {security, utils} from "../helpers";
import {connect} from "react-redux";
import {trackPromise} from "react-promise-tracker";
import {getResults, putConta, putContaCartola, putPassword} from "../api";
import {toast} from "react-toastify";

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      new_password: '',
      old_passord: '',
      login_cartola:'',
      senha_cartola:'',
    }
  }

  componentDidMount() {
    const {user} = this.props;
    if(user){
      this.setState({first_name: user.first_name,last_name: user.last_name,email: user.email, login_cartola: user.login_cartola});
    }
  }

  onChangeInput = event => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({[name]: value});
  };


  onSaveConta = async event => {
        event.preventDefault();
        await trackPromise(putConta({first_name: this.state.first_name,last_name: this.state.last_name,email: this.state.email})).then(
                ({ data }) => {
                    toast.success(data.mensagem);
                },
                error => {
                    toast.error(utils.handleError(error));
                }
            );;

  };

  onSaveContaCartola = async event => {
        event.preventDefault();
        await trackPromise(putContaCartola({login_cartola: this.state.login_cartola,password_cartola: this.state.password_cartola})).then(
                ({ data }) => {
                    toast.success(data.mensagem);
                },
                error => {
                    toast.error(utils.handleError(error));
                }
            );;

  };

  onSavePassword = async event => {
        event.preventDefault();
        await trackPromise(putPassword({old_password: this.state.old_password, new_password: this.state.new_password})).then(
                ({ data }) => {
                    toast.success(data.mensagem);
                },
                error => {
                    toast.error(utils.handleError(error));
                }
            );;

  };



  render() {
    const {user} = this.props;

    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row>
            <Col className="order-xl-1 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#bie" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="bg-white rounded-circle"
                          src={user.role_name && require('assets/img/'+user.role_name+'.png')}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">Plano {user.role_name=='bronze'?'PRO':'Grátis'}</span>
                          <span className="description">Seu plano atual</span>
                        </div>
                      </div>
                    </div>
                  </Row>

                  <div className="text-center">
                    <h3>
                      {user.first_name} {user.last_name}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Membro desde {utils.printDate(user.date_joined)}
                    </div>
                    {/*      <a className="btn btn-primary" href="/planos">
                            PLANOS
                        </a>*/}

                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Sua Conta</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form autocomplete="off">
                    <h6 className="heading-small text-muted mb-4">
                      Informações de Usuário
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Primeiro Nome
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="Lucky"
                              id="input-first-name"
                              name="first_name"
                              value={this.state.first_name}
                              onChange={this.onChangeInput}
                              placeholder="primeiro nome"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Sobrenome
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.last_name}
                              id="input-last-name"
                              name="last_name"
                              onChange={this.onChangeInput}
                              placeholder="sobrenome"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              name="email"
                              value={this.state.email}
                              onChange={this.onChangeInput}
                              placeholder="jesse@example.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <a className="btn btn-primary float-right" href="#bie" onClick={this.onSaveConta}>
                        Salvar
                      </a>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Informações de Acesso
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Senha atual
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-old-password"
                              type="password"
                              autocomplete="off"
                              name="old_password"
                              onChange={this.onChangeInput}
                              value={this.state.old_password}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Nova senha
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-new-password"
                              autocomplete="off"
                              type="password"
                              name="new_password"
                              onChange={this.onChangeInput}
                              value={this.state.new_password}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <a className="btn btn-primary float-right" href="#bie" onClick={this.onSavePassword}>
                        Salvar
                      </a>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">Conta no CartolaFC</h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              Usuário do Cartola FC
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="user-cartolafc"
                              type="text"
                              autocomplete="off"
                              name="login_cartola"
                              onChange={this.onChangeInput}
                              value={this.state.login_cartola}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Senha do Cartola FC
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="password-cartolafc"
                              type="password"
                              autocomplete="off"
                              onChange={this.onChangeInput}
                              name="password_cartola"
                              value={this.state.password_cartola}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                        <a className="btn btn-primary float-right" href="#bie" onClick={this.onSaveContaCartola}>
                        Salvar
                      </a>
                      </FormGroup>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}


function mapStateToProps(state) {
    const { logged, auth } = state.authentication;
    return {
            logged,
            user: auth.user,
        };
}

export default connect(mapStateToProps)(Profile);
