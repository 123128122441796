import React from "react";
import {Link} from "react-router-dom";
import {
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Row,
    UncontrolledCollapse,
    UncontrolledDropdown
} from "reactstrap";
import Info from "../Info";
import {connect} from "react-redux";

// reactstrap components

class AdminNavbar extends React.Component {
    render() {
        const { user, logged, data_fecha, rodada_id } = this.props;
        return (
            <>
                <Navbar className="navbar-top navbar-horizontal navbar-dark"
                        expand="md" id="navbar-main">
                    <Container fluid>
                        <Link
                            className="h1 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                            to="/"
                        >
                            <i className={this.props.brandIcon} /> {this.props.brandText}
                        </Link>
                        <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                            <div className="navbar-collapse-header d-md-none">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/">
                                            <img
                                                alt="..."
                                                src={user && user.role_name && require('assets/img/' + user.role_name + '.png')}
                                            />
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button
                                            className="navbar-toggler"
                                            id="navbar-collapse-main"
                                        >
                                            <span/>
                                            <span/>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav className="ml-auto" navbar>
                                {/*<NavItem>*/}
                                {/*    <Info data_fecha={data_fecha} rodada_id={rodada_id} />*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink className="nav-link-icon" to="/sobre" tag={Link}>
                                        <i className="ni ni-support-16"/>
                                        <span className="nav-link-inner--text">Sobre</span>
                                    </NavLink>
                                </NavItem>
                                {!logged &&
                                <NavItem>
                                    <NavLink
                                        className="nav-link-icon"
                                        to="/cadastro"
                                        tag={Link}
                                    >
                                        <i className="ni ni-circle-08"/>
                                        <span className="nav-link-inner--text">Cadastrar</span>
                                    </NavLink>
                                </NavItem>}
                                {!logged &&
                                <NavItem>
                                    <NavLink
                                        className="nav-link-icon"
                                        to="/login"
                                        tag={Link}
                                    >
                                        <i className="ni ni-key-25"/>
                                        <span className="nav-link-inner--text">Login</span>
                                    </NavLink>
                                </NavItem>}
                            </Nav>
                            {logged &&
                            <Nav className="align-items-center d-none d-md-flex" navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle className="pr-0" nav>
                                        <Media className="align-items-center">
                      <span className="bg-white avatar avatar-sm rounded-circle">
                        <img
                            alt="..."
                            src={user && user.role_name && require('assets/img/' + user.role_name + '.png')}
                        />
                      </span>
                                            <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {user.first_name}
                        </span>
                                            </Media>
                                        </Media>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem className="noti-title" header tag="div">
                                            <h6 className="text-overflow m-0">Seja bem vindo!</h6>
                                        </DropdownItem>
                                        <DropdownItem to="/perfil" tag={Link}>
                                            <i className="ni ni-single-02"/>
                                            <span>Conta</span>
                                        </DropdownItem>
                                        <DropdownItem to="/sobre" tag={Link}>
                                            <i className="ni ni-support-16"/>
                                            <span>Suporte</span>
                                        </DropdownItem>
                                        <DropdownItem divider/>
                                        <DropdownItem to="/logout" tag={Link}>
                                            <i className="ni ni-user-run"/>
                                            <span>Sair</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                            }
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { mercado } = state.loadMercado;
    const { logged, auth } = state.authentication;
    return {
            logged,
            user: auth.user,
            data_fecha: mercado ? mercado.data_fecha:'',
            rodada_id: mercado ? mercado.rodada.codigo: '',
        };
}

export default connect(mapStateToProps)(AdminNavbar);
