/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import {security} from "../../helpers";

class UserHeader extends React.Component {
  render() {
    const user = security.getLoggedUser();
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-5 d-flex align-items-center"

        >
          {/* Mask */}
          <span className="mask bg-gradient-orange opacity-1" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                  <br />
                <h1 className="display-2 text-white">Olá  {user.first_name}</h1>
                <p className="text-white mt-0 mb-5">
                  Este é seu perfil. Aqui você atualiza suas informações e configurações gerais. Salve seu acesso no CartolaFc para que a Máquina posso escalar e salvar diretamente.
                </p>
                {/*<Button
                  color="info"
                  href="#bie"
                  onClick={e => e.preventDefault()}
                >
                  Edit profile
                </Button>*/}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
