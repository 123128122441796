import React, {Component} from 'react';
import {Media, Table} from "reactstrap";
import {connect} from "react-redux";

class MostPlayers extends Component {
    render() {
        const {destaques, max} = this.props;
        return (
            <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Posição</th>
                                        <th scope="col">Escalações</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {destaques && destaques.slice(0,max).map((jogador, index) => (
                                            <tr key={"mp"+index}>
                                                <th scope="row">
                                                    <Media className="align-items-center">
                                                        <span className="mr-3 glyphicon">
                                                            <img
                                                                alt="..."
                                                                src={jogador.Atleta.foto.replace('FORMATO','140x140')}
                                                            />
                                                        </span>
                                                        <Media>
                                                                <span className="mb-0 text-md ">
                                                                  {jogador.Atleta.apelido}
                                                                </span>
                                                        </Media>
                                                    </Media>
                                                    </th>
                                                <td>
                                                    <Media className="align-items-center">
                                                        <span className="mr-3 glyphicon">
                                                            <img
                                                                alt="..."
                                                                src={jogador.escudo_clube}
                                                            />
                                                        </span>
                                                    </Media>
                                                </td>
                                                <td>{jogador.posicao_abreviacao}</td>
                                                <td>
                                                    <i className="fas fa-arrow-up text-success mr-3"/>{" "}
                                                    {jogador.escalacoes}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
        );
    }
}

MostPlayers.propTypes = {};

function mapStateToProps(state) {
    const { mercado } = state.loadMercado;
    return {destaques: mercado? mercado.destaques:null};
}

export default connect(mapStateToProps)(MostPlayers);
