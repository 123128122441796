import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from "reactstrap";
import ModalDetail from "./ModalDetail";

export default class ModalHelp extends ModalDetail {
    render() {
        const { title, isOpen} = this.props;
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={isOpen}
                toggle={(e) => this.toggleModal(e)}
            >
                <div className="modal-header">
                    <h2 className="modal-title" id="helpModalLabel">
                        Ajuda: {title}
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => this.toggleModal(e)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {this.props.children}
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => this.toggleModal(e)}
                    >
                        Fechar
                    </Button>
                </div>
            </Modal>
        );
    }
}


ModalHelp.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
};
