import {getMercado, getEscalacao, getOlheiro, getSearchLigas, getTimesLiga, getPontuacao, setCompartilhar, getCompartilhar, getJogadorStats} from "../api"


export const cartolaService = {
    mercado,
    escalar,
    olheiro,
    pontuacao,
    searchLigas,
    saveCompartilhar,
    searchTimesLiga,
    getCompartilharLink,
    jogadorStats
};

async function mercado() {
    return await getMercado();
}

async function pontuacao() {
    return await getPontuacao();
}

async function escalar(config) {
    return await getEscalacao(config);
}


async function olheiro(config) {
    return await getOlheiro(config);
}

async function searchLigas(config) {
    return await getSearchLigas(config);
}

async function searchTimesLiga(config) {
    return await getTimesLiga(config);
}

async function saveCompartilhar(msgm, image) {
    return await setCompartilhar({msgm: msgm, img: image});
}

async function getCompartilharLink(rodada, code) {
    return await getCompartilhar({rodada, code});
}


async function jogadorStats(id) {
    return await getJogadorStats({jid: id});
}

