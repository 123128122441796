import React from 'react';
import PropTypes from 'prop-types';

const ClubFilter = props => {
    return (
        <div className="form-inline float-right" onChange={props.onChange}>
            <div className="frmc custom-control custom-radio ml-1">
                <input id="ftimeT" name="ftime" type="radio"
                       className="custom-control-input" value="T" checked={props.mode == 'T'}/>
                <label className="custom-control-label" htmlFor="ftimeT">Todos</label>
            </div>
            <div className="frmc custom-control custom-radio ml-1">
                <input id="ftimeN" name="ftime" type="radio"
                       className="custom-control-input" value="N" checked={props.mode == 'N'}/>
                <label className="custom-control-label" htmlFor="ftimeN">Nenhum</label>
            </div>
            <div className="frmc custom-control custom-radio ml-1">
                <input id="ftimeC" name="ftime" type="radio"
                       className="custom-control-input" value="C" checked={props.mode == 'C'}/>
                <label className="custom-control-label" htmlFor="ftimeC">Casa</label>
            </div>
            <div className="frmc custom-control custom-radio ml-1">
                <input id="ftimeF" name="ftime" type="radio"
                       className="custom-control-input" value="V" checked={props.mode == 'V'}/>
                <label className="custom-control-label" htmlFor="ftimeF">Visitante</label>
            </div>
            <div className="frmc custom-control custom-radio ml-1">
                <input id="ftimeR" name="ftime" type="radio"
                       className="custom-control-input" value="R" checked={props.mode == 'R'}/>
                <label className="custom-control-label" htmlFor="ftimeR">Melhores para
                    Rodada</label>
            </div>
        </div>
    );
};

ClubFilter.propTypes = {
    onChange: PropTypes.func,
    mode: PropTypes.string.isRequired
};

export default ClubFilter;
