import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Badge, Col, Media, Row} from "reactstrap";
import {utils} from "../helpers";

const ClubItem =  props => {
    const { club, modo, total } = props;

    return <Row>
        <Col xs="1" sm="1" md="1">
                    <span className="small">
                        <Badge className="text-responsive bg-primary text-white small posicao">{club.ranking[modo]}º</Badge>
                    </span>
        </Col>
        <Col xs="6" sm="6" md="6">
            <Media className="align-items-center">
                            <span className="mr-3 glyphicon">
                                <img
                                    alt="..."
                                    src={club.url_escudo_png}
                                />
                            </span>
                <Col xs="12" sm="12" md="12">
                                <span className="text-responsive  h2 mb-0 text-md ">
                                  {club.nome}
                                </span>
                    <br/>
                    <span className="small gray">
                                {club.nome_cartola} - C$ {club.patrimonio}
                            </span>
                </Col>
            </Media>
        </Col>
        <Col xs="1" sm="1" md="1" className={"text-right"}>
        </Col>
        <Col xs="4" sm="4" md="4" className={"text-right"}>
                    <span className="text-responsive h3 mb-0 text-md ">
                        <span className="text-responsive  h3 mb-0 text-md ">
                        {modo == "campeonato" &&
                        <i className={club.variacao[modo] > 0 ? "text-success ni ni-bold-up" : (club.variacao[modo] == 0 ? "" : "text-danger ni ni-bold-down")}/>}
                            {modo != "campeonato" &&
                            <Badge className="bg-success text-white posicao">{total}</Badge>}
                        </span>
                    <Badge
                        className="bg-default text-white posicao">{utils.printFloat(club.pontos[modo])}</Badge>
                    </span>
        </Col>
    </Row>;
}

ClubItem.propTypes = {
    club: PropTypes.any,
    modo: PropTypes.any,
    total: PropTypes.number,
};

export default ClubItem;
