import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {userActions} from "../actions";


function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = {
    logout: userActions.logout
};

class Logout extends Component {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        return (
            <>
                <Redirect to="/login" />
            </>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Logout);
