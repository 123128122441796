import {createActions, createReducer} from 'reduxsauce'

let pontuacao = null;

const INITIAL_STATE = pontuacao ? {pontuacao, searching: false, error: false} : {searching: false, error: false};

const {Types, Creators} = createActions({
    request: null,
    success: ['pontuacao'],
    failure: null
});


function request(state = INITIAL_STATE, action) {
    return {...state, config: action.config, searching: true}
}

function success(state = INITIAL_STATE, action) {
    return {...state, pontuacao: action.pontuacao, searching: false}
}

function failure(state = INITIAL_STATE, action) {
    return {...state, error: true, searching: false}
}

const HANDLERS = {
    [Types.REQUEST]: request,
    [Types.SUCCESS]: success,
    [Types.FAILURE]: failure,
};

export const PontuacaoCreators = Creators;

export const pontuacaoReducer = createReducer(INITIAL_STATE,HANDLERS);
