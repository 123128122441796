import React, {Component} from 'react';
import {Media, Progress, Table} from "reactstrap";
import {trackPromise} from "react-promise-tracker";
import {getResults} from "../../api";


const printInteger = (valor) => {
    return new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(valor)
};

const printPercentLabel = (valor) => {
    if (valor >= 66) {
        return 'bg-gradient-success';
    } else if (valor >= 33 && valor < 66) {
        return 'bg-gradient-info';
    } else if (valor < 33) {
        return 'bg-gradient-danger';
    }
};

class ResultPlayers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            title: 'Resultados da Rodada'
        }
    }

    render() {
        const {itens} = this.props;
        return (
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                    <th scope="col">Time</th>
                    <th scope="col">CFC</th>
                    <th scope="col">MQ</th>
                    <th scope="col"/>
                </tr>
                </thead>
                <tbody>
                {itens && itens.slice(0,5).map((item, index) => (
                   <tr key={"player"+index}>
                    <th scope="row">
                        <Media className="align-items-center">
                            <span className="mr-3 glyphicon">
                                <img
                                    alt="..."
                                    src={item.escudo}
                                />
                            </span>
                            <Media>
                                    <span className="mb-0 text-md ">
                                      {item.nome}
                                    </span>
                            </Media>
                    </Media>
                    </th>
                    <td>{item.pmp}</td>
                    <td>{item.pcf}</td>
                    <td>
                        <div className="d-flex align-items-center">
                            <span className="mr-2">{printInteger(item.porcentagem)}%</span>
                            <div>
                                <Progress
                                    max="100"
                                    value={printInteger(item.porcentagem)}
                                    barClassName={printPercentLabel(item.porcentagem)}
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                ))}
                </tbody>
            </Table>
        );
    }
}

ResultPlayers.propTypes = {};

export default ResultPlayers;
