import {cartolaConstants} from "../constants";
import {indexdb, store} from "../helpers";
import {cartolaActions} from "../actions";

indexdb.get('config_olheiro').then( config_olheiro => {
    if(config_olheiro){
        store.dispatch(cartolaActions.requestOlheiro(config_olheiro));
        indexdb.get('jogadores').then( jogadores => {
            store.dispatch(cartolaActions.successOlheiro(jogadores));
        })
    }
});


let jogadores = null;

const initialState = jogadores ? { jogadores } : {};

export function olheiroMachine(state = initialState, action) {
  switch (action.type) {
    case cartolaConstants.OLHEIRO_START:
      return {
          ...state,
        config: action.config,
        searching: true,
      };
      case cartolaConstants.OLHEIRO_SUCCESS:
      return {
          ...state,
          jogadores: action.jogadores,
          searching: false,
      };
      case cartolaConstants.OLHEIRO_FAILED:
      return {
          ...state,
          error: action.error,
          searching: false,
      };
      case cartolaConstants.OLHEIRO_RESET:
      return {
          ...state,
          jogadores: null,
      };
    default:
      return state
  }
}
