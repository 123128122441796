import React from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {Badge, Card, CardBody, CardHeader, Col, Container, FormGroup, Row} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import ClubList from "../components/ClubList";
import Slider from "../components/Slider";
import {cartolaActions} from "../actions";
import {connect} from "react-redux";
import {trackPromise} from "react-promise-tracker";
import ClubFilter from "../components/ClubFilter";
import PlayersCont from "../containers/PlayersCont";


class Mining extends React.Component {
    state = {
        view_players: false,
        setor:'',
        times_modo: 'T',
        times: [],
        times_selected: [],
        slides_list_def : [],
        slides_list_ata : [],
        fs: 1,
        pe: 1,
        a: 1,
        ft: 1,
        fd: 1,
        ff: 1,
        g: 1,
        i: 1,
        pp: 1,
        rb: 1,
        fc: 1,
        gc: 1,
        ca: 1,
        cv: 1,
        sg: 1,
        dd: 1,
        dp: 1,
        gs: 1
    };

    resetState = JSON.parse(JSON.stringify(this.state));


    labels_scout = {"fs":"Falta Sofrida (FS)",
							"pe":"Passe Incompleto (PI)",
							"a": "Assistência (A)",
							"ft": "Final. na Trave (FT)",
							"fd": "Final. Defendida (FD)",
							"ff": "Final. Fora (FF)",
							"g": "Gol (G)",
							"i": "Impedimento (I)",
							"pp": "Penalti Perdido (PP)",

							"rb": "Desarme (DS)",
							"fc": "Falta Cometida (FC)",
							"gc": "Gol Contra (GC)",
							"ca": "Cartão Amarelo (CA)",
							"cv": "Cartão Vermelho (CV)",
							"sg": "Sem Gols (SG)",
							"dd": "Defesa Difícil (DD)",
							"dp": "Defesa de Pênalti (DP)",
							"gs": "Gol Sofrido (GS)"
							};

    label_setor = {"ata":"Atacantes",
                   "mei":"Meias",
                   "zag":"Zagueiros",
                   "lat":"Laterais",
                   "gol":"Goleiros",
                   "tec":"Técnicos"};

    config_scout_ata ={"ata":["g","a","ft","fd","ff","fs"],
							   "mei":["fs","a","fd","ff","g"],
							   "zag":["fs","ff"],
							   "lat":["a","fs","fd","ff"],
							   "gol":[],
							   "tec":["g","a"]};

    config_scout_def ={"ata":["rb"],
							   "mei":["rb","fc"],
							   "zag":["rb","fc","sg"],
							   "lat":["rb","fc","sg"],
							   "gol":["sg","dd","dp"],
							   "tec":["sg"]};


    handleChange = event => {
        event.preventDefault();
        const { value } = event.target;
        this.draw_slider(value);
        this.state.setor = value;
        console.log(this.label_setor[this.state.setor])
    };

    draw_slider(value){
        let lista_slider_ata = this.config_scout_ata[value];
        let lista_slider_def = this.config_scout_def[value];

        let list_ata = [];
        for(let i of lista_slider_ata){
            list_ata.push(this.add_slider(i,0,this.labels_scout[i]));
        }
        let list_def = [];
        for(let i of lista_slider_def){
            list_def.push(this.add_slider(i,0,this.labels_scout[i]));
        }

        this.setState({slides_list_def:list_def});
        this.setState({slides_list_ata:list_ata});
    }

    add_slider(nome, value, label){
            return (
                <Slider
                    label={label}
                    id={nome}
                    value={this.state[nome]}
                    onChange={this.onChangeSlider}
                    max={5}
                    min={0}
                    ticks={[0, 1, 2, 3, 4, 5]}
                    ticks_snap_bounds={1}
                    dual="true"
                />
            );
    }

    resetState = JSON.parse(JSON.stringify(this.state));

    timesModoSelect(times_modo){
     const times = this.props.times;
        const partidas = this.props.partidas;
        let selected_times = [];
        if(times){
            if(times_modo=='T'){
                for(let t of times){
                    selected_times.push(t.id);
                }
            }else if(times_modo=='C'){
                for(let p of partidas){
                    selected_times.push(p.time_casa);
                }
            }else if(times_modo=='V'){
                for(let p of partidas){
                    selected_times.push(p.time_visitante);
                }
            }else if(times_modo=='R'){
                for(let p of partidas){
                    if(p.ordem<=5){
                        if(p.bhome<=p.baway){
                            selected_times.push(p.time_casa);
                        }else{
                            selected_times.push(p.time_visitante);
                        }
                    }
                }
            }
        }
        this.setState({times_modo, times: selected_times})
    }

    onChangeTimes = (times) => {
        this.setState({times_selected: times});
    };


    setTimesModo = (event) => {
        const times_modo = event.target.value;
        this.timesModoSelect(times_modo);
    };

    componentDidMount(){
        const times_modo = this.state.times_modo;
        this.timesModoSelect(times_modo);
        this.setState(this.props.config);
    }

    onChangeSlider = (e, item) => {
        const { value } = e.target;
        this.setState({[item]:value});

        const val = this.refs['setor'].value;
        this.draw_slider(val)
    };

    resetConfig = (e) => {
        this.setState(this.resetState);
        const times_modo = this.resetState.times_modo;
        this.timesModoSelect(times_modo)
    };

    submitConfig = (e) => {
        e.preventDefault();
        let criterios = [];
        for(let c of this.config_scout_ata[this.state.setor]){
            criterios.push(c);
        }
        for(let c of this.config_scout_def[this.state.setor]){
            criterios.push(c);
        }

        let pesos = [];
        for(let c of criterios){
            pesos.push(this.state[c]);
        }

        let config = {
            setor: this.state.setor,
            times: this.state.times_selected,
            criterios: criterios,
            pesos: pesos,
        };

        trackPromise(this.props.fetchOlheiro(config));
        this.setState({view_players: true});
        //this.props.history.push("/time");

    };

    render() {
        const { jogadores, times_mercado, partidas, rodada_id, resetOlheiro } = this.props;
        return (
            <>
                <Header title="Olheiro" subtitle="escale seu time" gradient="default"/>
                {/* Page content */}
                <Container className=" mt--7">
                    {/* Table */}
                    {!jogadores &&
                        <Row>
                        <div className=" col">
                            <Card className=" shadow mb-2">
                                <CardHeader className=" bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Escolha o setor</Badge>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup className="row">
                                                <label
                                                    className="col-md-2 col-form-label"
                                                    htmlFor="input-cartoletas">
                                                    Setor:
                                                </label>
                                                <select ref="setor" id="setor" name="setor" value={this.state.setor} onChange={this.handleChange} className="form-control col-md-10 btn-lg text-lg p-2">
                                                    <option value="">-</option>
                                                    <option value="ata">Atacantes</option>
                                                    <option value="mei">Meias</option>
                                                    <option value="zag">Zagueiros</option>
                                                    <option value="lat">Laterais</option>
                                                    <option value="gol">Goleiros</option>
                                                    <option value="tec">Técnicos</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className=" shadow mb-2">
                                <CardHeader className="bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Escolha os times</Badge>
                                    <ClubFilter mode={this.state.times_modo} onChange={this.setTimesModo} />
                                </CardHeader>
                                <CardBody>
                                    <ClubList times={this.props.times} selected_clubs={this.state.times} onChange={this.onChangeTimes}/>
                                </CardBody>
                            </Card>

                            <Card className=" shadow mb-2">
                                <CardHeader className="bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Escolha de 1 a 10 o peso dos scouts atacando</Badge>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                    {!this.state.slides_list_ata.length>0 &&
                                        <div className="row form-group def">
                                        <h3>Escolha um setor acima</h3>
                                        </div>
                                    }
                                    {this.state.slides_list_ata.length>0 &&
                                     this.state.slides_list_ata.map( (item, index) => (
                                             <Col key={"sca"+index} md={12}>
                                                 {item}
                                             </Col>
                                         ))
                                    }
                                    </Row>

                                </CardBody>
                            </Card>

                            <Card className=" shadow mb-2">
                                <CardHeader className="bg-transparent">
                                    <Badge className="text-white bg-gradient-orange mb-0">Escolha de 1 a 10 o peso dos scouts defendendo</Badge>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                    {!this.state.slides_list_def.length>0 &&
                                        <div className="row form-group def">
                                        <h3>Escolha um setor acima</h3>
                                        </div>
                                    }
                                    {this.state.slides_list_def.length>0 &&
                                     this.state.slides_list_def.map( (item, index) => (
                                             <Col key={"scd"+index} md={12}>
                                                 {item}
                                             </Col>
                                         ))
                                    }
                                    </Row>
                                </CardBody>
                            </Card>

                             <div className="btn-group btn-group-justified " role="group" aria-label="...">
                                <div className="btn-group" role="group">
                                    <button type="button" id="limpar" className="btn btn-primary" onClick={this.resetConfig}>
                                        <span className="d-inline-flex">
                                        <i className="ni ni-fat-remove"></i><span
                                        className="d-none d-sm-block"> Limpar</span>
                                        </span>
                                    </button>
                                </div>
                                <div className="btn-group" role="group">
                                    <button type="button" id="shareTime"
                                            className="btn btn-primary share"
                                            data="Minha configuração na Máquina do Cartola. #Rodada3"
                                            item="maquinadocartolaconfig">
                                        <span className="d-inline-flex">
                                        <i className="fa fa-share"></i><span
                                        className="d-none d-sm-block"> Compartilhar</span>
                                        </span>
                                        </button>
                                </div>
                                <div className="btn-group" role="group">
                                    <button type="button" id="pesquisarTime" disabled={!this.state.setor}
                                            onClick={this.submitConfig}
                                            className="btn btn-primary ">
                                        <span className="d-inline-flex">
                                        <i className="fa fa-search"></i><span
                                        className="d-none d-sm-block"> Pesquisar</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Row>
                    }
                    {jogadores &&
                        <Row>
                            <div className=" col">
                                <PlayersCont id='atleta_id' label={this.label_setor[this.state.setor]}
                                             titleModal={"Desempenho do Jogador #"+rodada_id}
                                             jogadores={jogadores}  times={times_mercado}
                                             partidas={partidas} rodada_id={rodada_id}
                                             reset={resetOlheiro} />
                            </div>
                        </Row>
                    }
                </Container>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { mercado } = state.loadMercado;
    const { jogadores, config } = state.olheiroMachine;
    const times = [];
    let jogadores_list = null;
    const atletas = mercado ? mercado.atletas : null;

    if(jogadores && atletas) {
        jogadores_list = [];
        for (const j of jogadores) {
            jogadores_list.push(atletas[j.id]);
        }
    }
    if(mercado){
        for (const [key, value] of Object.entries(mercado.times)) {
          times.push(value);
        }
    }
    return {
        times,
        times_mercado: mercado ? mercado.times : {},
        jogadores: jogadores_list,
        rodada_id: mercado ? mercado.rodada.codigo : '',
        partidas: mercado? mercado.partidas:[],
        config: config ? config: {},
    }
}

const actionCreatorsToProps = {
    fetchOlheiro: cartolaActions.fetchOlheiro,
    resetOlheiro: cartolaActions.resetOlheiro
};

export default connect(mapStateToProps, actionCreatorsToProps)(Mining);
