import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Badge} from "reactstrap";
import Datedown from "./Datedown";

class Info extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className='h1 pt-2  text-white text-uppercase d-none d-lg-inline-block'>
                    <Badge className="text-default">#Rodada {this.props.rodada_id}</Badge>
                </div>
                <span className="p-1"></span>
                <div className='h1 pt-2  text-white text-uppercase d-none d-lg-inline-block'>
                    <Badge className="text-lowercase text-default">
                      <Datedown date_close={this.props.data_fecha} label_close="Mercado Fechado !" />
                    </Badge>
                </div>
            </>
        );
    }
}

Info.propTypes = {
    rodada_id: PropTypes.string,
    data_fecha: PropTypes.string
};

export default Info;
