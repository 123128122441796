import { combineReducers } from "redux";
import { resettableReducer } from 'reduxsauce'

import { registration } from './registration.reducer'
import { authentication } from './authentication.reducer'
import { loadMercado } from './cartola.reducer'
import { escalarMachine } from './escalar.reducer'
import { olheiroMachine } from './olheiro.reducer'
import { parcialReducer } from './parcial.reducer'
import { pontuacaoReducer } from './pontuacao.reducer'

const resettable = resettableReducer('RESET')

const rootReducer = combineReducers({
    registration,
    authentication,
    loadMercado,
    escalarMachine,
    olheiroMachine,
    parcialMachine: parcialReducer, //resettable(parcialReducer),
    loadPontuacao: resettable(pontuacaoReducer)
});

export default rootReducer;
