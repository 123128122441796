import {setLogin, setLoginFacebook, setLoginGoogle, setRegister} from "../api"
import {indexdb} from "../helpers";


export const userService = {
    register,
    login,
    logout,
    loginSocial
};

async function register(user) {
    return await setRegister(user);
}

async function login(username, password) {
    return await setLogin(username, password);
}

async function loginSocial(access_token, provider) {
    if(provider==='facebook'){
        return await setLoginFacebook(access_token);
    } else if(provider==='google'){
        return await setLoginGoogle(access_token);
    }
}

function logout() {
    indexdb.remove('auth');
}
