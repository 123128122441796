import React, {Component} from "react";
// reactstrap components
import {Container} from "reactstrap";
// core components
import Header from "../components/Headers/Header";
import TeamCont from "../containers/TeamCont";
import {utils} from "../helpers";
import {cartolaActions} from "../actions";
import {connect} from "react-redux";


class Team extends Component {
  render() {
    const {time, config, searching} = this.props;
    return (
      <>
        <Header title="Time" subtitle="time selecionado" gradient="info" />
        {/* Page content */}
        <Container className="mt--7">
          {/* List */}
          <TeamCont id={"atleta_id"} time={time} redirect={true} config={config} searching={searching} />
        </Container>
      </>
    );
  }
}


function mapStateToProps(state) {
    const {mercado} = state.loadMercado;
    const {searching, time, config} = state.escalarMachine;
    const atletas = mercado ? mercado.atletas : null;

    let time_calculado;
    if (time) {
        time_calculado = {...time};
        if (mercado && atletas) {
            let list_j = [];
            let ttp = 0;
            let ttc = 0;
            for (let jid of time_calculado.jogadores) {
                list_j.push(atletas[jid]);
                ttp += atletas[jid].pontos_num;
                ttc += atletas[jid].preco_num;
            }

            time_calculado.jogadores = utils.ordenaJogadores(list_j);
            time_calculado.total_pontos = ttp;
            time_calculado.total_cartoletas = ttc;

        }
    }

    return {
        searching,
        time: time_calculado,
        config: config
    };
}

export default connect(mapStateToProps)(Team);

