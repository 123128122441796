/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {Badge, Col, Media, Progress, Row} from "reactstrap";
// core components
import ObjectList from "../components/ObjectList";
import {getResults} from "../api";
import {trackPromise} from "react-promise-tracker";
import {utils} from '../helpers'
import {connect} from "react-redux";
import PlayersList from "../components/PlayersList";
import * as PropTypes from "prop-types";

function ResultItem(props) {
    return <Row>
        <Col xs="1" sm="1" md="1">
            <span className="small"><Badge
                className="bg-primary text-white badge-circle">{props.index + 1}º</Badge></span>
        </Col>
        <Col xs="4" sm="4" md="4">
            <Media className="align-items-center">
                            <span className="mr-3 glyphicon">
                                <img
                                    alt="..."
                                    src={props.item.escudo}
                                />
                            </span>
                <Media>
                                    <span className="text-responsive  h2 mb-0 text-md ">
                                      {props.item.nome}
                                    </span>
                </Media>
            </Media>
        </Col>
        <Col xs="2" sm="2" md="2">
                    <span className="text-responsive  h3 mb-0 text-md ">
                        <Badge className="text-white bg-orange">{props.item.pmp}</Badge>
                    </span>
        </Col>
        <Col xs="2" sm="2" md="2">
                    <span className="text-responsive  h3 mb-0 text-md ">
                        <Badge className="badge-default">{props.item.pcf}</Badge>
                    </span>
        </Col>
        <Col xs="3" sm="3" md="3">
            <Progress
                max="100"
                value={utils.printInteger(props.item.porcentagem)}
                barClassName={utils.printPercentLabel(props.item.porcentagem)}
            >
                {utils.printInteger(props.item.porcentagem)}%
            </Progress>

        </Col>
    </Row>;
}

ResultItem.propTypes = {
    index: PropTypes.any,
    item: PropTypes.any
};

class ResultsCont extends ObjectList {
    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            title: 'Resultados da Rodada'
        }
    }

    getObjectItem = (item, index) => {
        return (
            <ResultItem index={index} item={item}/>
        );
    };

    getTitleList = () => {
        return (
            <Row className="pl-2 pr-2">
                <Col xs="1" sm="1" md="1">
                    <span className="small text-right">#</span>
                </Col>
                <Col xs="4" sm="4" md="4">
                    <span className="small text-right">Times</span>
                </Col>
                <Col xs="2" sm="2" md="2">
                    <span className="small text-right">MQ</span>
                </Col>
                <Col xs="2" sm="2" md="2">
                    <span className="small text-right">CFC</span>
                </Col>
                <Col xs="3" sm="3" md="3">
                    <span className="small text-right">%</span>
                </Col>
            </Row>
        );
    };

    getHeaderList = () => {
        return (
            <h2 className="mb-0 text-responsive "><span className="glyphicon">
                <img alt="..." src={require("assets/img/escudo/sprite_escudos.png")} /></span> Resultados da #Rodada {this.state.rodada_id}
            </h2>
        );
    };

    getFooterList = () => {
        return (
            <>
                <span>
                    MQ=Pontuação pela Máquina, CFC=Pontuação pelo CartolaCF, %=O quanto foi utlizado da Máquina no Time do CartolaFC
                </span>
		            <br />
		        <b>*Os dados acima são referentes a rodada passada.</b>
            </>
        );
    };

    getHeaderModal = () => {
        const { times, partidas, resultados, rodada_id, atletas, status } = this.props;
        const { itemSelect } = this.state;
        const cid = itemSelect;
        let item = null;
        let index = 0;

        for (let c of resultados){
            if(c.id===cid){
                item = c;
                break;
            }
            index+=1;
        }

        if(item && this.state.itemSelect){
            return <ResultItem index={index} item={item}/>;
        }
    };

    getItemModal = () => {
        const { times, partidas, rodada_id} = this.props;
        const { itens, itemSelect } = this.state;
        const cid = itemSelect;
        let item = null;
        let index = 0;

        for (let c of itens){
            if(c.id===cid){
                item = c;
                break;
            }
            index+=1;
        }

        let j_list = [];
        let capitao = 0;
        if(item){
            let time = item.time_json;
            capitao = time.capitao_id;
            for(let j of time.atletas){
                    if(times[j.clube_id]){
                        j_list.push(j);
                    }
            }
        }

        if(item) {
            return <PlayersList jogadores={j_list} times={times} rodada={rodada_id} capitao={capitao} />;
        }
    };


    updateAndNotify = () => {
            const {resultados, rodada_id} = this.props;
            this.setState({itens:resultados , rodada_id: rodada_id});
    }

    async componentDidMount(){
            this.updateAndNotify();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.resultados !== this.props.resultados) {
            this.updateAndNotify();
        }
    };

  render() {
    return (
      <>
        {super.render()}
      </>
    );
  }
}

function mapStateToProps(state) {
    const { mercado } = state.loadMercado;
    const { logged, auth } = state.authentication;
    let resultados = [];

    if (mercado && mercado.resultados) {
        for (let t in mercado.resultados) {
            resultados.push(mercado.resultados[t]);
        }
    }

    return {
            logged,
            resultados,
            user: auth.user,
            rodada_id: mercado ? mercado.rodada.codigo : '',
            status: mercado ? mercado.status : 0,
            partidas: mercado? mercado.partidas:[],
            times: mercado? mercado.times:{},
            atletas: mercado? mercado.atletas:[],
        };
}

export default connect(mapStateToProps)(ResultsCont);
