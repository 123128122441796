/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {Badge, Col, Row} from "reactstrap";
// core components
import ObjectList from "../components/ObjectList";
import JogadorDetail from "../components/JogadorDetail";
import JogadorItem from "../components/JogadorItem";
import {trackPromise} from "react-promise-tracker";
import {cartolaService} from "../services";
import {utils} from "../helpers";


class PlayersCont extends ObjectList {
    constructor(props) {
        super(props);
        this.state = {
            itens: this.props.jogadores,
            title: 'Olheiro da Rodada',
            jogadorData: [],
            titleModal: ""
        };

    }

    getObjectItem = (item, index) => {
        const {times, partidas} = this.props;
        return (
            <JogadorItem id={index} item={item} partidas={partidas} times={times}/>
        );
    };

    getTitleList = () => {
        return (
            <Row className="pl-2 pr-2">
                <Col>
                    <span className="h2 text-white uppercase">{this.props.label}</span>
                </Col>
                <Col className="text-right">
                    <Badge className="text-gray-dark">ULT</Badge>
                    <Badge className="text-gray-dark">PTS* </Badge>
                    <Badge className="text-gray-dark">C$ </Badge>

                </Col>
            </Row>
        );
    };

    getHeaderList = () => {
        return (
            <h2 className="mb-0 text-responsive "><span className="glyphicon">
                <img alt="..." src={require("assets/img/escudo/sprite_escudos.png")}/></span> Olheiro
                #Rodada {this.state.rodada_id}
            </h2>
        );
    };

    getFooterList = () => {
        return (
            <>
                <i className="fa fa-hand-pointer"></i> Clique em cima do Jogador para detalhes, ULT= Pontuação na última
                rodada, * = Média do Jogador, $ = Preço em cartoletas
            </>
        );
    };

    toggleModal = async (e, state, id = null) => {
        e.preventDefault();
        const {rodada_id} = this.props;
        const {itens} = this.state;
        const jid = id;
        let item;
        let titleModal;

        for (let j of itens) {
            if (j.atleta_id === jid) {
                item = j;
                break;
            }
        }

        const {data} = await trackPromise(cartolaService.jogadorStats(id));
        let dataTranspose = null;

        if(jid){
            titleModal = "Desempenho do Jogador: "+item.apelido+ " #"+rodada_id;
        }


        if(!utils.isEmpty(data)){
            dataTranspose = data[0].map((_, colIndex) => data.map(row => row[colIndex]));
        }

        this.setState({
            [state]: !this.state[state], itemSelect: id, jogadorData: dataTranspose, titleModal: titleModal
        });
    };


    getItemModal = () => {
        const {times, partidas} = this.props;
        const {itens, itemSelect, jogadorData} = this.state;
        const jid = itemSelect;
        let item = null;

        for (let j of itens) {
            if (j.atleta_id === jid) {
                item = j;
                break;
            }
        }

        if(jogadorData){
            return <JogadorDetail data={jogadorData} item={item} times={times} partidas={partidas} />
        }
    };

    async componentDidMount() {
        this.setState({itens: this.props.jogadores, rodada_id: this.props.rodada_id});
    };

    back = (e) => {
        this.props.reset();
    };

    buttonLeft = () => {
        return (<button type="button" id="limpar" className="btn btn-primary" onClick={this.back}>
            <span className="d-inline-flex">
            <i className="fa fa-backward"></i><span
            className="d-none d-sm-block"> Voltar</span>
            </span>
        </button>);
    };

    render() {
        return (
            <>
                {super.render()}
            </>
        );
    }
}


export default PlayersCont;
