import React from "react";
import ReactBootstrapSlider from "react-bootstrap-slider";
import {FormGroup} from "reactstrap";

class Slider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <FormGroup className="row">
                    <label
                        className="col-md-3 col-form-label"
                        htmlFor={this.props.id}>
                        {this.props.label} <b className="float-right">{this.props.value}</b>
                    </label>
                    <div className={this.props.dual ? "col-md-9 pt-2":"col-md-7 pt-2"}>
                        <ReactBootstrapSlider
                            change={(e) => this.props.onChange(e,this.props.id)}
                            slideStop={(e) => this.props.onChange(e,this.props.id)}
                            name={this.props.id}
                            tooltip="hide"
                            slider-handle="round"
                            orientation="horizontal" {...this.props}/>
                    </div>
                    {!this.props.dual &&
                    <div className="col-md-2">
                        {this.props.children}
                    </div>
                    }
                </FormGroup>
            </>
        )
    }
};

export default Slider;
