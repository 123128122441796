import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Col, Media, Progress, Row} from "reactstrap";
import {utils} from "../helpers";

function TimeItem(props) {
    return <Row>
        <Col xs="1" sm="1" md="1">
            <span className="small"><Badge
                className="bg-primary text-white badge-circle">{props.item.classificacao}º</Badge></span>
        </Col>
        <Col xs="5" sm="6" md="6">
            <Media className="align-items-center">
                            <span className="mr-3 glyphicon">
                                <img
                                    alt="..."
                                    src={require("assets/img/escudo/" + props.item.slug + ".png")}
                                />
                            </span>
                <Media>
                                    <span className="text-responsive  h2 mb-0 text-md ">
                                      {props.item.nome}
                                    </span>
                </Media>
            </Media>
        </Col>
        <Col xs="1" sm="2" md="2">
            <h2>
                <i className={props.item.sobe > 0 ? "text-success ni ni-bold-up" : (props.item.sobe == 0 ? "text-gray ni ni-fat-delete" : "text-danger ni ni-bold-down")}/>
            </h2>
        </Col>
        <Col xs="4" sm="3" md="3">
            <Progress
                max="100"
                value={utils.printPercent(props.item.score)}
                barClassName={utils.printPercentLabel(props.item.score * 100)}
            >
                {utils.printPercent(props.item.score)}%
            </Progress>
        </Col>
    </Row>;
}

TimeItem.propTypes = {item: PropTypes.any};

export default TimeItem;
