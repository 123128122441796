import React, {Component} from "react";
// reactstrap components
import {Container, Button, Row, Col, Card, CardHeader, CardBody, CardTitle, CardText} from "reactstrap";
// core components
import Header from "../components/Headers/Header";
import TeamCont from "../containers/TeamCont";
import {utils} from "../helpers";
import {connect} from "react-redux";
import {trackPromise} from "react-promise-tracker";
import {getEscalacao} from "../api";
import ModalDetail from "../components/Modals/ModalDetail";


class Selection extends Component {

    constructor(props) {
        super(props);
        this.state = {time: null, timeModal: false, searching: false, price: 999};
    }

    updateAndNotify = async (price) => {
        const {atletas, mercado, destaques} = this.props;

        let formacoes = [1, 2, 3, 4, 5, 6, 7];

        if(price > 150){
            formacoes = [1, 3]
        }else if(price > 100 && price <= 150){
            formacoes = [1,2,3,4,6]
        }

        let config_in = {
            cartoleta: price,
            modo: "off",
            modocart: "off",
            atas: 1,
            meis: 1,
            lats: 1,
            zags: 1,
            gols: 1,
            tecs: 1,
            ipj: 7,
            ipp: 4,
            ipt: 1,
            rodadas: 10,
            times: [262, 264, 265, 266, 275, 276, 277, 280, 282, 284, 285, 286, 292, 293, 315, 327, 354, 356, 373, 1371],
            formacoes: formacoes,
            jogadores: destaques
        };

        //if (!this.state.time && this.state.price!==price) {
            const {data} = await trackPromise(getEscalacao(config_in));
            let time_calculado;
            const {time, mensagem } = data;
            if (time) {
                time_calculado = {...time};
                if (mercado && atletas) {
                    let list_j = [];
                    let ttp = 0;
                    let ttc = 0;
                    for (let jid of time_calculado.jogadores) {
                        list_j.push(atletas[jid]);
                        ttp += atletas[jid].pontos_num;
                        ttc += atletas[jid].preco_num;
                    }

                    time_calculado.jogadores = utils.ordenaJogadores(list_j);
                    time_calculado.total_pontos = ttp;
                    time_calculado.total_cartoletas = ttc;
                    this.setState({time: time_calculado, config:config_in, searching:false, price: price});
                }
            }
        //}
    };


    componentDidMount() {
        // this.updateAndNotify();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.time !== this.state.time) {
    //         this.updateAndNotify();
    //     }
    // };

    toggleModal = (e, state, value = 100) => {
        this.setState({searching:true})
        e.preventDefault();
        //console.log(e.target);
        if(!this.state[state]){
            this.updateAndNotify(value);
            this.setState({
                [state]: !this.state[state], value: value
            });
        }else{
            this.setState({
                [state]: !this.state[state], value: value, time: null
            });
        }


    };

    render() {
        const {atletas, logged} = this.props;
        return (
            <>
                <Header title="Seleção da Rodada" subtitle="time selecionado" gradient="info"/>
                {/* Page content */}
                <Container className="mt--7 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="12" md="12">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent">
                                    <div className="text-muted text-center">
                                        <h1>Seleções da Rodada </h1>
                                        <small>A Máquina escolheu três times com diferentes preços</small>
                                    </div>
                                </CardHeader>
                                <CardBody className="">
                                    <Row>
                                      <Col sm="4">
                                        <Card body>
                                          <CardTitle tag="h3">Seleção de Estrela</CardTitle>
                                          <CardText>Time formado com até C$ 300</CardText>
                                          <button type="button" id="time1" className="btn btn-primary" onClick={(e) => this.toggleModal(e, "timeModal",300)} data-dismiss="modal">
                                                            <span className="d-inline-flex">
                                                            <i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i>
                                                            </span>
                                        </button>
                                        </Card>
                                      </Col>
                                      <Col sm="4">
                                        <Card body>
                                          <CardTitle tag="h3">Seleção Moderada</CardTitle>
                                          <CardText>Time formado com até C$ 130</CardText>
                                          <button type="button" id="time1" className="btn btn-primary" onClick={(e) => this.toggleModal(e, "timeModal",120)} data-dismiss="modal">
                                                            <span className="d-inline-flex">
                                                            <i className="fa fa-star"></i><i className="fa fa-star"></i>
                                                            </span>
                                            </button>
                                        </Card>
                                      </Col>
                                        <Col sm="4">
                                        <Card body>
                                          <CardTitle tag="h3">Seleção Econômica</CardTitle>
                                          <CardText>Time formado com até C$ 75</CardText>
                                            <button type="button" id="time1" className="btn btn-primary" onClick={(e) => this.toggleModal(e, "timeModal",75)} data-dismiss="modal">
                                                            <span className="d-inline-flex">
                                                            <i className="fa fa-star"></i>
                                                            </span>
                                            </button>
                                        </Card>
                                      </Col>
                                    </Row>




                                    <ModalDetail state="timeModal" title="Seleção da Rodada !"
                                                 isOpen={this.state.timeModal}
                                                 toggleModal={this.toggleModal}
                                                 notShare={true}
                                                 getHeader={() => (<></>)} >

                                        {this.state.searching && !this.state.time && <Row><Col><h2>Carregando...</h2></Col></Row>}
                                        {this.state.time && <TeamCont id={"atleta_id"} time={this.state.time} redirect={false} config={this.state.config} searching={this.state.searching} />}
                                    </ModalDetail>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}


function mapStateToProps(state) {
    const {mercado} = state.loadMercado;
    const {logged, auth} = state.authentication;
    const atletas = mercado ? mercado.atletas : null;
    const destaques = mercado ? mercado.destaques : [];
    let destaques_list = [];
    for (let d of destaques) {
        destaques_list.push(d.Atleta.atleta_id);
    }

    return {
        mercado,
        atletas,
        logged,
        auth,
        destaques: destaques_list
    };
}

export default connect(mapStateToProps)(Selection);
