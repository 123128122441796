import {cartolaConstants} from "../constants";

const initialState = {};

export function loadMercado(state = initialState, action) {
  switch (action.type) {
    case cartolaConstants.FETCH_MERCADO_SUCCESS:
      return {
        mercado: action.mercado
      };
    default:
      return state
  }
}
