/** SimpleIDB **/
import localForage from 'localforage';

export const indexdb = {
    get,
    set,
    remove
}


async function get(key) {
		try {
			return await localForage.getItem(key);
		} catch (err) {
			console.log(err);
		}
	}

function set(key, value) {
		return localForage.setItem(key, value)
}

function remove(key) {
		return localForage.removeItem(key)
	}


