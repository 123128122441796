import moment from "moment";
import 'moment/locale/pt-br'

export const utils = {
    printInteger,
    printPercentLabel,
    printPercent,
    addOrRemove,
    printFloat,
    ordenaJogadores,
    isEmpty,
    printDate,
    handleError,
    select_path
};

function printInteger(valor) {
    return new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(valor)
}

function printFloat(valor) {
    return new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(valor)
}

function printPercent(valor){
    return new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(valor * 100)
}

function printDate(data) {
    moment.locale('pt-br')
    return moment(data).format('LLL');
}

function printPercentLabel(valor) {
    if (valor >= 66) {
        return 'bg-gradient-success';
    } else if (valor >= 33 && valor < 66) {
        return 'bg-gradient-info';
    } else if (valor < 33) {
        return 'bg-gradient-danger';
    }
}

function addOrRemove(array, item) {
    const exists = array.includes(item)

    if (exists) {
        return array.filter((c) => {
            return c !== item
        })
    } else {
        const result = array
        result.push(item)
        return result
    }
}

function ordenaJogadores(jogadores) {

    for(let j in jogadores){
        if (jogadores[j].posicao_id == 1)
            jogadores[j]['chave'] = 1;
        else if (jogadores[j].posicao_id == 3)
            jogadores[j]['chave'] = 2;
        else if (jogadores[j].posicao_id == 2)
            jogadores[j]['chave'] = 3;
        else if (jogadores[j].posicao_id == 4)
            jogadores[j]['chave'] = 4;
        else if (jogadores[j].posicao_id == 5)
            jogadores[j]['chave'] = 5;
        else if (jogadores[j].posicao_id == 6)
            jogadores[j]['chave'] = 6;
    }

    jogadores.sort((a, b) => (a.chave > b.chave) ? 1 : -1);

    return jogadores;
}

function isEmpty(obj) {
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}


function handleError(error) {
        let error_msgm = '';
        if(error.response){
            let err_obj;
            try{
                for (var [key, value] of Object.entries(error.response.data)) {
                    if(key === 'non_field_errors'){
                        error_msgm += value +'\n';
                    } else{
                        error_msgm += key.toUpperCase() + ': ' + value +'\n';
                    }
                }
            }catch (e) {
                error_msgm = error.message;
            }

        }else{
            error_msgm = error.message;
        }
        return error_msgm;
}


function select_path(layout) {
  return layout==='/auth'?'/app':'';
}
