import React from "react";
// reactstrap components
import {Badge, Col, Media, Progress, Row} from "reactstrap";
// core components
import ObjectList from "../components/ObjectList";
import {getClubs} from "../api";
import {trackPromise} from 'react-promise-tracker';
import {utils} from '../helpers'
import {connect} from "react-redux";
import ClubItem from "../components/ClubItem";
import PlayersList from "../components/PlayersList";
import * as PropTypes from "prop-types";
import TimeItem from "../components/TimeItem";


class ClubsCont extends ObjectList {
    constructor(props) {
        super(props);
        this.setState({
            itens: [],
            title: 'Classificação',
        });
    }

    getObjectItem = (item, index) => {
        return (
            <TimeItem key={'club' + index} item={item}/>
        );
    };

    getTitleList = () => {
        return (
            <Row className="pl-2 pr-2">
                <Col xs="1" sm="1" md="1">
                    <span className="small text-right">#</span>
                </Col>
                <Col xs="5" sm="6" md="6">
                    <span className="small text-right">Times</span>
                </Col>
                <Col xs="1" sm="2" md="2">
                    <span className="small text-right"></span>
                </Col>
                <Col xs="4" sm="3" md="3">
                    <span className="small text-right">Desempenho</span>
                </Col>
            </Row>
        );
    };

    getHeaderList = () => {
        return (
            <h2 className="mb-0 text-responsive "><span className="glyphicon">
                <img alt="..." src={require("assets/img/escudo/sprite_escudos.png")}/></span> Classificação
                #Rodada {this.state.rodada_id}
            </h2>
        );
    };

    getFooterList = () => {
        return (
            <>
                Clique em <i className="ni ni-button-play"></i> do time para detalhes, Força do time para
                rodada {this.state.rodada_id}, Classificação do time no campeonato
            </>
        );
    };

    getHeaderModal = () => {
        const {times_mercado, status} = this.props;
        const tid = this.state.itemSelect;
        const time = times_mercado[tid];

        if (time && tid) {
            const item = time;
            return (<TimeItem key={'time' + tid} item={item}/>);
        }
    };

    getItemModal = () => {
        const {times_mercado, partidas, rodada_id, atletas, status} = this.props;
        const tid = this.state.itemSelect;

        if (times_mercado && tid) {
            let j_list = [];
            if (atletas) {
                for (let jid in atletas) {
                    if (atletas[jid] && atletas[jid].clube_id == tid) {
                        if (status === 2) {
                            if (atletas[jid] && atletas[jid].pontos && atletas[jid].pontos != '-') {
                                let j = atletas[jid];
                                j['pontos'] = atletas[jid].pontos;
                                j_list.push(j);
                            }
                        } else {
                            if (atletas[jid].status_id === 7) {
                                let j = atletas[jid];
                                if (atletas[jid]) {
                                    j['pontos'] = atletas[jid].pontos;
                                } else {
                                    j['pontos'] = '-';
                                }
                                j_list.push(j);
                            }
                        }
                    }
                }
            }

            return (
                <>
                    {(j_list.length > 0) &&
                    <PlayersList jogadores={j_list} times={times_mercado} partidas={partidas} rodada={rodada_id}
                                 parcial={status == 2}/>}
                    {(!j_list.length > 0 && status === 1) && <Col><h2>Time não joga essa rodada !</h2></Col>}
                    {(!j_list.length > 0 && status === 2) && <Col><h2>Aguardando time jogar nessa rodada !</h2></Col>}
                </>
            );
        }
    };

    updateAndNotify = () => {
            const {times, rodada_id} = this.props;
            this.setState({
                itens: times.sort((a, b) => (a.classificacao > b.classificacao) ? 1 : -1),
                rodada_id: rodada_id
            });
    }

    async componentDidMount(){
            this.updateAndNotify();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.times !== this.props.times) {
            this.updateAndNotify();
        }
    };

    render() {
        return (
            <>
                {super.render()}
            </>
        );
    }
}

function mapStateToProps(state) {
    const {mercado} = state.loadMercado;
    const {logged, auth} = state.authentication;
    let times = [];

    if (mercado && mercado.times) {
        for (let t in mercado.times) {
            times.push(mercado.times[t]);
        }
    }

    return {
        logged,
        times,
        user: auth.user,
        rodada_id: mercado ? mercado.rodada.codigo : '',
        status: mercado ? mercado.status : 0,
        times_mercado: mercado ? mercado.times : {},
        partidas: mercado ? mercado.partidas : [],
        atletas: mercado ? mercado.atletas : [],
    };
}

export default connect(mapStateToProps)(ClubsCont);
