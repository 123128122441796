
import React, {Component} from "react";
// reactstrap components
import {Container} from "reactstrap";
// core components
import Header from "../components/Headers/Header";
import MatchCont from "../containers/MatchCont";


export default class Match extends Component {
  render() {
    return (
      <>
        <Header title="Partidas" subtitle="partidas da rodada" gradient="info" />
        {/* Page content */}
        <Container className="mt--7">
          {/* List */}
          <MatchCont />
        </Container>
      </>
    );
  }
}
