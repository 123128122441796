import React from "react";
import {Col, Row} from "reactstrap";
import {utils} from "../helpers";


class FormationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_formations: [],
        }
    }

    componentDidMount() {
        this.fetchTrans();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected_formations !== this.props.selected_formations) {
            this.fetchTrans()
        }
    }

    handleChange = (e) => {
        if (this.props.formations && this.props.formations.length > 0) {
            for (let f of this.props.formations) {
                this.refs['f' + f.id].checked = false;
            }
        }

        const id = parseInt(e.target.attributes['data-id'].value);
        let selectlist = this.state.selected_formations;
        this.setState({selected_formations: utils.addOrRemove(selectlist, id)}, this.selectState);
    };

    fetchTrans() {
        const selected_formations = [];
        if (this.props.selected_formations && this.props.formations.length > 0) {
            for (let f of this.props.formations) {
                this.refs['f' + f.id].checked = false;
            }
            if (this.props.selected_formations && this.props.selected_formations.length > 0) {
                for (let f of this.props.selected_formations) {
                    selected_formations.push(f);
                }
            }
            this.setState({selected_formations: selected_formations}, this.selectState);
        }

    }

    selectState() {
        if (this.state.selected_formations && this.state.selected_formations.length > 0) {
            for (let f of this.state.selected_formations) {
                this.refs['f' + f].checked = true;
            }
        }
        this.props.onChange(this.state.selected_formations);
    }


    render() {
        const {formations} = this.props;
        return (
            <Row className="form-check-inline">{
                formations.map(( formation ) => (
                    <Col key={formation.id} className="frmc custom-control custom-checkbox ml-1">
                        <input id={"f" + formation.id}
                               ref={"f" + formation.id}
                               name={"f" + formation.id}
                               data-id={formation.id}
                               type="checkbox"
                               className="custom-control-input"
                               onClick={this.handleChange}
                               defaultChecked="true"/>
                        <label className="custom-control-label" htmlFor={"f" + formation.id}> {formation.value}</label>
                    </Col>
                ))
            }
            </Row>
        );
    }
};

export default FormationList;
