import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Col, Media, Row} from "reactstrap";
import {utils} from "../helpers";

const JogadorItem = props => {

    const POSICAO = {1: {id: 1, nome: "Goleiro", abreviacao: "gol"},
                        2: {id: 2, nome: "Lateral", abreviacao: "lat"},
                        3: {id: 3, nome: "Zagueiro", abreviacao: "zag"},
                        4: {id: 4, nome: "Meia", abreviacao: "mei"},
                        5: {id: 5, nome: "Atacante", abreviacao: "ata"},
                        6: {id: 6, nome: "Técnico", abreviacao: "tec"},
    };

    const { item, times, capitao, partidas, parcial } = props;
    return (
        <Row key={'j' + props.id}>
                <Col xs="4" sm="4" md="4">
                    <Row>
                        <Col xs="3" sm="4" md="4">
                            <Media className="align-items-center">
                                    <span className="glyphicon"><img src={item.foto.replace('FORMATO','140x140')} className="backimage" /></span>
                                        {times &&
                                            <img src={require("assets/img/escudo/" + times[item.clube_id].slug + ".png")} className="escudo-placar"
                                             onError="this.onerror=null; this.src='/static/img/escudo/sprite_escudos.png'"/>
                                        }
                            </Media>
                        </Col>
                        <Col xs="7" sm="8" md="8">
                            <div className="row d-none d-sm-block">
                                    <div className="text-responsive  h2 ml-1 mb-0 text-md">{item.apelido}</div>
                            </div>
                            <div className="row">
                                <Badge className="bg-primary uppercase small text-white posicao">{POSICAO[item.posicao_id].abreviacao}</Badge>
                                {(item.atleta_id == capitao)  &&
                                        <Badge className="bg-default small text-white posicao">CAP</Badge>
                                }
                            </div>
                        </Col>
                    </Row>
                    <div className="d-block d-sm-none">
                                    <div className="text-responsive  h2 ml-1 mb-0 text-md">{item.apelido}</div>
                    </div>
                </Col>
                { partidas && <Col xs="1" sm="2" md="3" className="text-center partida-logo">
                    {partidas.map(
                        (partida) => {
                            if(partida.time_casa == item.clube_id || partida.time_visitante == item.clube_id){
                                return (
                                    <table>
                                        <tr>
                                            <td><img alt={times[partida.time_casa].slug} src={require("assets/img/escudo/" + times[partida.time_casa].slug + ".png")} className={"text-right"} /></td>
                                            <td><b> X </b></td>
                                            <td><img alt={times[partida.time_visitante].slug} src={require("assets/img/escudo/" + times[partida.time_visitante].slug + ".png")} /></td>
                                        </tr>
                                    </table>
                                );
                            }
                        })
                    }
                </Col>}
                <Col className="text-right">
                    {parcial &&
                        <Badge className="bg-gray text-white mr-1">{item.pontos  ? (item.pontos && (item.atleta_id == capitao))? utils.printFloat(2 * item.pontos):utils.printFloat(item.pontos):'-'}</Badge>
                    }
                    {!parcial &&
                        <>
                            <Badge className="bg-gray text-white mr-1">{(item.pontos_num && (item.atleta_id == capitao))? utils.printFloat(2 * item.pontos_num):utils.printFloat(item.pontos_num)}</Badge>
                            <Badge className="text-white point mr-1">* {utils.printFloat(item.media_num)}</Badge>
                            <Badge className="text-white money mr-1">$ {utils.printFloat(item.preco_num)}</Badge>
                        </>
                        }
                </Col>
            </Row>
    );
};

JogadorItem.propTypes = {
    item: PropTypes.object.isRequired,
    times: PropTypes.array.isRequired,
    capitao: PropTypes.number,
    partidas: PropTypes.array.isRequired
};

export default JogadorItem;
