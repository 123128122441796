import React from "react";
// reactstrap components
import {Card, CardBody, CardHeader, Container, Row} from "reactstrap";
import {DiscussionEmbed} from 'disqus-react';
import Header from "../components/Headers/Header";

class About extends React.Component {
    render() {
        return (
            <>
                <Header title="Sobre" subtitle="" gradient="info"/>
                <Container className="mt--7 pb-5">
                    <Row className="justify-content-center">
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <h1 className=" mb-0">A Máquina do Cartola FC</h1>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                      <span className="pl-4 pr-4">
                    A união da Inteligência Artificial com o Futebol e alguns computadores pensando pela escolha do melhores jogadores em poucos cliques.
             Configure a máquina com suas estratégias, salve e ganhe esse campeonato!
                      </span>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <div className="col-sm-6 col-md-6">
                                            <ul>
                                                <li>Escalar os melhores jogadores para o CartolaFC</li>
                                                <li>Parcias dos Times e por Ligas</li>
                                                <li>Acompanhar a classificação dos times</li>
                                                <li>Acompanhar partidas</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <ul>
                                                <li>Olheiro para escolher jogadores</li>
                                                <li>Salvar diretamente no CartolaFC</li>
                                                <li>Acompanhar os resultados da máquina</li>
                                                <li><a className="btn btn-primary btn-lg" href="#comentarios"
                                                       role="button">Comentários
                                                    e Sugestões</a></li>
                                            </ul>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-12">
                                            <img src={require("assets/img/maquinalogo.png")} style={{width: '100%'}}
                                                 alt="CartolaFC"/>
                                            <a name="comentarios"></a>
                                            <DiscussionEmbed
                                                style={{width: '100%'}}
                                                shortname='maquinacfc'
                                                config={
                                                    {
                                                        url: "https://maquinadocartola.com/sobre",
                                                        identifier: "maquinacfc",
                                                        title: "Sobre a Máquina do Cartola",
                                                        language: 'pt_BR'
                                                    }
                                                }
                                            />
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default About;
