import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from "reactstrap";
import ShareButton from "../ShareButton";

export default class ModalDetail extends Component {
    constructor(props) {
        super(props);
    }

    toggleModal = (e) => {
        e.preventDefault();
        this.props.toggleModal(e, this.props.state);
    };

    getHeader = () => {
        const { title, isOpen } = this.props;
        if(!this.props.getHeader()){
            return (<>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.toggleModal(e)}>
                    <span aria-hidden="true">&times;</span>
                </button>

                <h4 className="modal-title" id="itemModalLabel">{title}</h4>
            </>);
        }
        return this.props.getHeader();
    };

    getExtraButtons = () => {
        const { extraButtons } = this.props;
        return extraButtons ? extraButtons():'';
    };

    render() {
        const { title, isOpen, notShare } = this.props;
        return (
            <Modal
                className="modal-dialog-centered modal-lg"
                isOpen={isOpen}
                toggle={(e) => this.toggleModal(e)}
            >
                <div className="modal-header">
                    <div className="container-fluid">
                    {
                        this.getHeader()
                    }
                    </div>
                </div>
                <div id={"modalDetail"}>
                    {this.props.children}
                </div>
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => this.toggleModal(e)}
                    >
                        Fechar
                    </Button>
                    {!notShare &&
                        <ShareButton idElement={"#modalDetail"} msgm={title} />
                    }
                    {
                        this.getExtraButtons()
                    }
                </div>
            </Modal>
        );
    }
}


ModalDetail.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
};
