/*!

*/
import axios from "axios";
import { setupCache } from 'axios-cache-adapter'
import {security} from "./helpers";


// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000
});


// Create `axios` instance passing the newly created `cache.adapter`
const api1 = axios.create({
  adapter: cache.adapter
});

let API_VERSION = "/api/v1/";
let ROOT_URL = "https://maquinadocartola.com"
axios.defaults.baseURL = ROOT_URL + API_VERSION;

const api_urls = {};
api_urls['clubs'] = 'times';
api_urls['matches'] = 'partidas';
api_urls['results'] = 'resultados';

api_urls['register'] = 'users/register';
api_urls['put-conta'] = 'users/update';
api_urls['put-conta-cartola'] = 'users/cartola';
api_urls['put-password'] = 'users/passwd';


api_urls['login'] = 'login';
api_urls['login-facebook'] = 'login/facebook';
api_urls['login-google'] = 'login/facebook';
api_urls['login-refresh'] = 'login/refresh';
api_urls['mercado'] = 'mercado';
api_urls['pontuacao'] = 'pontuacao';
api_urls['compartilhar'] = 'compartilhar';
api_urls['logout'] = 'logout';
api_urls['proxy'] = ROOT_URL+'/proxy';

api_urls['maquina'] = 'cartola/escalar';
api_urls['olheiro'] = 'cartola/olheiro';
api_urls['salvarcartola'] = 'cartola/salvar';


api_urls['search-ligas'] = 'cartola/ligas';
api_urls['search-clubes'] = 'cartola/clubes';
api_urls['search-times-liga'] = 'cartola/times-liga';
api_urls['jogador-stats'] = 'cartola/dados-jogador';

api_urls['payment-order'] = 'payment/order';


const getClubs = async (data={}) => {
    return await axios.get(api_urls['clubs'], { params:data});
};

const getResults = async (data={}) => {
    return await axios.get(api_urls['results'], { params:data});
};

const setRegister = async (data) => {
    return await axios.post(api_urls['register'],data);
};

const setLogin = async (username, password) => {
    return await axios.post(api_urls['login'],{ username: username, password: password});
};

const setLoginFacebook = async (access_token) => {
    return await axios.post(api_urls['login-facebook'],{ access_token: access_token});
};

const setLoginGoogle = async (access_token) => {
    return await axios.post(api_urls['login-google'],{ access_token: access_token});
};

const setCompartilhar = async (data) => {
    return await axios.post(api_urls['compartilhar'],data);
};

const getCompartilhar = async (data) => {
    return await axios.get(api_urls['compartilhar'],{params:data});
};

const getMercado = () => {
    return axios({
        url: api_urls['mercado'],
        method: 'get'
    });
};

const getPontuacao = async () => {
    return await axios.get(api_urls['pontuacao']);
};

const getEscalacao = async (data) => {
    return await axios.post(api_urls['maquina'],data);
};

const getOlheiro = async (data) => {
    return await axios.post(api_urls['olheiro'],data , {headers:security.getAuthHeader()});
};

const getLogout = async () => {
    return await axios.get(api_urls['logout']);
};

const getMatches = async (data={}) => {
    return await axios.get(api_urls['matches'], { params:data});
};

const getSearchLigas = async (data) => {
    return await axios.get(api_urls['search-ligas'],{ params:data});
};

const getSearchClubes = async (data) => {
    return await axios.get(api_urls['search-clubes'],{ params:data});
};

const getTimesLiga = async (data) => {
    return await axios.get(api_urls['search-times-liga'],{ params:data});
};

const getJogadorStats = async (data) => {
    return await axios.get(api_urls['jogador-stats'],{ params:data});
};

const putConta = async (data) => {
    return await axios.put(api_urls['put-conta'],data,{headers:security.getAuthHeader()});
};

const putContaCartola = async (data) => {
    return await axios.put(api_urls['put-conta-cartola'],data,{headers:security.getAuthHeader()});
};

const putPassword = async (data) => {
    return await axios.put(api_urls['put-password'],data, {headers:security.getAuthHeader()});
};

const putSalvarCartola = async (data) => {
    return await axios.put(api_urls['salvarcartola'],data);
};

const getOrder = async (data={}) => {
    return await axios.get(api_urls['payment-order'], { params:data, headers:security.getAuthHeader()});
};


export {
    getClubs,
    getResults,
    setRegister,
    setLogin,
    getLogout,
    setLoginFacebook,
    setLoginGoogle,
    getMercado,
    getPontuacao,
    getEscalacao,
    getOlheiro,
    getMatches,
    getSearchLigas,
    getSearchClubes,
    getTimesLiga,
    setCompartilhar,
    getCompartilhar,
    getJogadorStats,
    putConta,
    putContaCartola,
    putSalvarCartola,
    putPassword,
    getOrder,
    api_urls,
};
