import React from "react";
// reactstrap components
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {RegisterForm} from "../containers/RegisterForm";
import Header from "../components/Headers/Header";

class Register extends React.Component {

    pushForm = (data) => {
        console.log(data);
    }

    render() {
        return (
            <>
                <Header title="Sobre" subtitle="" gradient="info"/>
                <Container className="mt--7 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="6" md="8">
                            <Card className="bg-secondary shadow border-0">
                                <CardHeader className="bg-transparent pb-5">
                                    <div className="text-muted text-center">
                                        <h1>Cadastro de usuário</h1>
                                        <small>Realize seu cadastro e tenha acesso a muitas funcionalidades da
                                            MaquinaDoCartola.com</small>
                                    </div>
                                </CardHeader>
                                <CardBody className="px-lg-5 py-lg-5">
                                    <RegisterForm pushForm={this.pushForm}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Register;
