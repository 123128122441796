//synchronous action creator
import moment from 'moment'
import {cartolaService} from "../services/cartola.service";
import {cartolaConstants} from "../constants";
import {ParcialCreators} from "../reducers/parcial.reducer";
import {PontuacaoCreators} from "../reducers/pontuacao.reducer";
import {toast} from "react-toastify";
import {indexdb} from "../helpers";

/*
    asynchronous thunk action creator
  calls the api, then dispatches the synchronous action creator
*/

export const cartolaActions = {
    fetchMercado,
    fetchEscalacao,
    fetchParcial,
    fetchOlheiro,
    resetEscalacao,
    resetOlheiro,
    resetParcial,
    fetchPontuacao,
    resetPontuacao,
    fetchMercadoSuccess,
    successEscalacao,
    requestEscalacao,
    successOlheiro,
    requestOlheiro
};

function fetchMercadoSuccess(mercado) {
    return {
        type: cartolaConstants.FETCH_MERCADO_SUCCESS,
        mercado,
    }
}


function fetchMercado(){
        return async (dispatch, getState)=> {
            let mercado = getState().loadMercado.mercado;

            // if(!mercado){
            //     mercado = await indexdb.get('mercado');
            //     dispatch(fetchMercadoSuccess(mercado));
            // }

            let diff_min = 99;
            if(mercado){
                const now = moment(new Date());
                const last_update = moment(mercado['data_update']);
                diff_min = now.diff(last_update, 'minutes');
            }

            //if(diff_min>1) {
                await cartolaService.mercado().then(
                    ({data}) => {
                        const mercado = data;
                        mercado['data_update'] = new Date();
                        const pontuacao = getState().loadPontuacao.pontuacao;
                        if (pontuacao && mercado) {
                            const atletas = pontuacao.atletas;
                            for (let jid in mercado.atletas) {
                                let atleta = mercado.atletas[jid];
                                if (atletas[jid]) {
                                    atleta['pontos'] = atletas[jid].pontuacao;
                                }
                            }
                        }
                        indexdb.set('mercado', mercado);
                        dispatch(fetchMercadoSuccess(mercado));
                        dispatch(PontuacaoCreators.success(pontuacao));
                    },
                    error => {
                        console.log(error);
                    }
                );
            //}
        };
}

function fetchPontuacao(config){
    return async (dispatch, getState) => {
        const pontuacao = getState().loadPontuacao.pontuacao;
            let diff_min = 99;
            if(pontuacao){
                const now = moment(new Date());
                const last_update = moment(pontuacao['data_update']);
                diff_min = now.diff(last_update, 'minutes');
            }

            if(diff_min>1) {
                dispatch(PontuacaoCreators.request());
                await cartolaService.pontuacao()
                    .then(
                        ({data}) => {
                            const pontuacao = data;
                            pontuacao['data_update'] = new Date();
                            const mercado = getState().loadMercado.mercado;
                            if (pontuacao && mercado) {
                                const atletas = pontuacao.atletas;
                                for (let jid in mercado.atletas) {
                                    let atleta = mercado.atletas[jid];
                                    if (atletas[jid]) {
                                        atleta['pontos'] = atletas[jid].pontuacao;
                                    }
                                }
                            }
                            indexdb.set('pontuacao', pontuacao);
                            dispatch(PontuacaoCreators.success(pontuacao));
                            dispatch(fetchMercadoSuccess(mercado));

                        },
                        error => {
                            dispatch(PontuacaoCreators.failure(error));
                        }
                    );
            }
    };
}

function requestEscalacao(config) { return { type: cartolaConstants.ESCALAR_START, config } }
function successEscalacao(time) { return { type: cartolaConstants.ESCALAR_SUCCESS, time } }

function fetchEscalacao(config){

    function failure(error) { return { type: cartolaConstants.ESCALAR_FAILED, error } }

    console.log(config);
    return async dispatch => {
        dispatch(requestEscalacao(config));
        await cartolaService.escalar(config)
            .then(
                ({ data }) => {
                    const result = data;
                    indexdb.set('config',config);
                    indexdb.set('time',result.time);
                    toast.success("Seleção da rodada escalada !");
                    dispatch(successEscalacao(result.time));
                },
                error => {
                    toast.error("Não foi possível escalar time com essa configuração, modifique e tente novamente !");
                    dispatch(failure(error));
                }
            );
    };
}

function requestOlheiro(config) { return { type: cartolaConstants.OLHEIRO_START, config } }
function successOlheiro(jogadores) { return { type: cartolaConstants.OLHEIRO_SUCCESS, jogadores } }


function fetchOlheiro(config){

    function failure(error) { return { type: cartolaConstants.OLHEIRO_FAILED, error } }

    console.log(config);
    return async dispatch => {
        dispatch(requestOlheiro(config));
        await cartolaService.olheiro(config)
            .then(
                ({ data }) => {
                    const result = data;
                    indexdb.set('config_olheiro', config);
                    indexdb.set('jogadores', result.jogadores);
                    dispatch(successOlheiro(result.jogadores));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
}

function fetchParcial(config){
    return async dispatch => {
        dispatch(ParcialCreators.parcialRequest(config));
        await cartolaService.searchTimesLiga(config)
            .then(
                ({ data }) => {
                    const liga = data;
                    indexdb.set('liga', liga);
                    dispatch(ParcialCreators.parcialSuccess(liga));
                },
                error => {
                    dispatch(ParcialCreators.parcialFailure(error));
                }
            );
    };
}

function resetParcial() {
    return ParcialCreators.reset();
}

function resetPontuacao() {
    return PontuacaoCreators.reset();
}

function resetOlheiro() {
    return {type: cartolaConstants.OLHEIRO_RESET};
}

function resetEscalacao() {
    return {type: cartolaConstants.ESCALAR_RESET};
}
