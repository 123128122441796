import Profile from "views/Profile.js";
import Match from "views/Match.js";
import Machine from "views/Machine.js";
import Partial from "views/Partial.js";

import Index from "views/Index.js";
import Login from "views/Login.js";
import Register from "views/Register.js";
import Results from "views/Results.js";
import About from "views/About.js";
import Share from "views/Share.js";
import Classification from "views/Classification.js";
import Logout from "views/Logout";
import Mining from "views/Mining";
import Team from "views/Team";
import Plans from "views/Plans";
import Selection from "views/Selection";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fa fa-tachometer-alt text-white",
    component: Index,
    layout: "/admin",
    access: false
  },
  {
    path: "/escalar",
    name: "Escalar",
    icon: "fa fa-gamepad text-white",
    component: Machine,
    layout: "/admin",
    access: false
  },
  {
    path: "/olheiro",
    name: "Olheiro",
    icon: "fa fa-eye text-white",
    component: Mining,
    layout: "/admin",
    access: true
  },
  {
    path: "/selecao",
    name: "Seleção",
    icon: "fa fa-star text-white",
    component: Selection,
    layout: "/admin",
    access: true
  },
  {
    path: "/parcial",
    name: "Parcial",
    icon: "ni ni-notification-70 text-white",
    component: Partial,
    layout: "/admin",
    access: false
  },
  {
    path: "/perfil",
    name: "Minha Conta",
    icon: "ni ni-notification-70 text-white",
    component: Profile,
    layout: "/admin",
    access: true,
    hidden: true
  },
  {
    path: "/partidas",
    name: "Partidas",
    icon: "fa fa-futbol text-white",
    component: Match,
    layout: "/admin",
    access: false
  },
  {
    path: "/classificacao",
    name: "Classificação",
    icon: "fa fa-sort-amount-up text-white",
    component: Classification,
    layout: "/admin",
    access: false
  },
  {
    path: "/resultados",
    name: "Resultados",
    icon: "ni ni-trophy text-white",
    component: Results,
    layout: "/admin",
    access: false
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-white",
    component: Login,
    layout: "/admin",
    access: false,
    hidden: true
  },
  {
    path: "/cadastro",
    name: "Cadastro",
    icon: "ni ni-circle-08 text-white",
    component: Register,
    layout: "/admin",
    access: false,
    hidden: true
  },
  {
    path: "/sobre",
    name: "Sobre",
    icon: "ni ni-key-25 text-white",
    component: About,
    layout: "/admin",
    access: false,
    hidden: true
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-user-run text-white",
    component: Logout,
    layout: "/admin",
    access: true,
    hidden: true
  },
  {
    path: "/time",
    name: "Time",
    icon: "ni ni-user-run text-white",
    component: Team,
    layout: "/admin",
    access: false,
    hidden: true
  },
  {
    path: "/compartilhar/:rodada/:code",
    name: "Compartilhar",
    icon: "ni ni-user-run text-white",
    component: Share,
    layout: "/admin",
    access: false,
    hidden: true
  },
  {
    path: "/planos",
    name: "Planos",
    icon: "ni ni-spaceship text-white",
    component: Plans,
    layout: "/admin",
    access: true,
    hidden: true
  },
];
export default routes;
