import React from "react";
// reactstrap components
import {Badge, Col, Row} from "reactstrap";
// core components
import ObjectList from "../components/ObjectList";
import {getMatches} from "../api";
import {trackPromise} from 'react-promise-tracker';
import {connect} from "react-redux";
import PartidaItem from "../components/PartidaItem";
import TimeItem from "../components/TimeItem";
import PlayersList from "../components/PlayersList";

class MatchCont extends ObjectList {
    constructor(props) {
        super(props);
        this.setState({
            itens: [],
            title: 'Classificação',
        });
    }

    getObjectItem = (item, index) => {
        const {times} = this.props;
        return ((item && times) &&
            <PartidaItem id={index} item={item} times={times}/>
        );
    };

    getTitleList = () => {
        return (
            <></>
        );
    };

    getHeaderList = () => {
        return (
            <h2 className="mb-0 text-responsive ">
                <span className="glyphicon"><img alt="..." src={require("assets/img/escudo/sprite_escudos.png")}/>X <img
                    alt="..." src={require("assets/img/escudo/sprite_escudos.png")}/></span> Partidas
                #Rodada {this.state.rodada_id}
            </h2>
        );
    };

    getFooterList = () => {
        return (
            <>
                Clique em cima <i className="fa fa-hand-pointer"></i> da Partida para ver detalhes,
                <Badge className="bg-default text-white small posicao">nº</Badge>=Número que indica a classificação no
                campeonato,
                <Badge className="bg-primary text-white small posicao">nº</Badge>=Número que indica prioridade para
                apostar
            </>
        );
    };

    getHeaderModal = () => {
        const {times, partidas} = this.props;
        const pid = this.state.itemSelect;
        let partida;

        for (let p of partidas) {
            if (p.id === pid) {
                partida = p
            }
        }

        if (partida && pid) {
            const item = partida;
            return <PartidaItem id={pid} item={item} times={times}/>;
        }
    };

    getItemModal = () => {
        const {times, partidas, rodada_id, atletas, status} = this.props;
        const pid = this.state.itemSelect;
        let partida = null;

        for (let p of partidas) {
            if (p.id === pid) {
                partida = p;
            }
        }

        if (partida && pid) {
            let j_list_c = [];
            let j_list_v = [];
            if (atletas) {
                for (let jid in atletas) {
                    if (atletas[jid]) {
                        if (atletas[jid].clube_id === partida.time_casa) {
                            if (status === 2) {
                                if (atletas[jid] && atletas[jid].pontos && atletas[jid].pontos != '-') {
                                    let j = atletas[jid];
                                    j['pontos'] = atletas[jid].pontos;
                                    j_list_c.push(j);
                                }
                            } else {
                                if (atletas[jid].status_id === 7) {
                                    let j = atletas[jid];
                                    if (atletas[jid]) {
                                        j['pontos'] = atletas[jid].pontos;
                                    } else {
                                        j['pontos'] = '-';
                                    }
                                    j_list_c.push(j);
                                }
                            }
                        } else if (atletas[jid].clube_id === partida.time_visitante) {
                            if (status === 2) {
                                if (atletas[jid] && atletas[jid].pontos && atletas[jid].pontos != '-') {
                                    let j = atletas[jid];
                                    j['pontos'] = atletas[jid].pontos;
                                    j_list_v.push(j);
                                }
                            } else {
                                if (atletas[jid].status_id === 7) {
                                    let j = atletas[jid];
                                    if (atletas[jid]) {
                                        j['pontos'] = atletas[jid].pontos;
                                    } else {
                                        j['pontos'] = '-';
                                    }
                                    j_list_v.push(j);
                                }
                            }
                        }
                    }
                }
            }
            return (
                <Row>
                    <div className={"col m--2 p--2"}>
                        <PlayersList jogadores={j_list_c} times={times} rodada={rodada_id} parcial={status === 2}/>
                    </div>
                    <div className={"col m--2 p--2"}>
                        <PlayersList jogadores={j_list_v} times={times} rodada={rodada_id} parcial={status === 2}/>
                    </div>

                </Row>
            );
        }
    };


    updateAndNotify = () => {
        const {rodada_id, partidas} = this.props
        //const {data} = await trackPromise(getMatches());
        this.setState({itens: partidas, rodada_id});
    }

    async componentDidMount() {
        this.updateAndNotify();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.partidas !== this.props.partidas) {
            this.updateAndNotify();
        }
    };


    render() {
        return (
            <>
                {super.render()}
            </>
        );
    }
}

function mapStateToProps(state) {
    const {mercado} = state.loadMercado;
    return {
        times: mercado ? mercado.times : null,
        partidas: mercado ? mercado.partidas : [],
        rodada_id: mercado ? mercado.rodada.codigo : '',
        status: mercado ? mercado.status : 0,
        atletas: mercado ? mercado.atletas : [],
    };
}

export default connect(mapStateToProps)(MatchCont);
