import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Card, CardBody, CardHeader, Col, Media, Row} from "reactstrap";
import {utils} from "../helpers";
import JogadorItem from "./JogadorItem";
import {Bar} from "react-chartjs-2";
import Chart from "chart.js";
import { chartOptions,parseOptions} from "variables/charts.js";
import * as ChartAnnotation from "chartjs-plugin-annotation";

const JogadorDetail = props => {

    const POSICAO = {1: {id: 1, nome: "Goleiro", abreviacao: "gol"},
                        2: {id: 2, nome: "Lateral", abreviacao: "lat"},
                        3: {id: 3, nome: "Zagueiro", abreviacao: "zag"},
                        4: {id: 4, nome: "Meia", abreviacao: "mei"},
                        5: {id: 5, nome: "Atacante", abreviacao: "ata"},
                        6: {id: 6, nome: "Técnico", abreviacao: "tec"},
    };

    if (window.Chart) {
            parseOptions(Chart, chartOptions());
    }

    const { item, times, capitao, partidas, parcial, data } = props;
    let chartJogador = {
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                callback: function (value) {
                                    if (!(value % 10)) {
                                        //return '$' + value + 'k'
                                        return value;
                                    }
                                }
                            }
                        }
                    ]
                },
                tooltips: {
                    callbacks: {
                        label: function (item, data) {
                            var label = data.datasets[item.datasetIndex].label || "";
                            var yLabel = item.yLabel;
                            var content = "";
                            if (data.datasets.length > 1) {
                                content += label;
                            }
                            content += " " + yLabel;
                            return content;
                        }
                    }
                }
            },
            data: {
                labels: data[0],
                datasets: [
                    {
                        label: "Pontos",
                        data: data[2],
                    },
                    {
                        label: "Cartoletas",
                        data: data[1],
                        hidden: true,
                        backgroundColor: "#2dce89"
                    }
                ]
            },
        };

    return (
        <Card>
            <CardHeader>
                <JogadorItem id={item.atleta_id} item={item} partidas={partidas} times={times} capitao={capitao} parcial={parcial}/>
            </CardHeader>
            <CardBody>
                <div className="chart">
                    <Bar
                        data={chartJogador.data}
                        options={chartJogador.options}
                        plugins={[ChartAnnotation]}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

JogadorDetail.propTypes = {
    item: PropTypes.object.isRequired,
    times: PropTypes.array.isRequired,
    capitao: PropTypes.number,
    partidas: PropTypes.array.isRequired
};

export default JogadorDetail;
