import React from "react";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
// reactstrap components
import {Badge, Card, CardBody, Col, Row} from "reactstrap";
// core components
import ObjectList from "../components/ObjectList";
import {getSearchLigas} from "../api";
import {trackPromise} from "react-promise-tracker";
import {connect} from "react-redux";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import {cartolaActions} from "../actions";
import PlayersList from "../components/PlayersList";
import ClubItem from "../components/ClubItem";


class PartialCont extends ObjectList {
    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            title: 'Parcial da Rodada',
            isLoading: false,
            options: [],
            liga: null,
            imagem: '',
            modo: 'rodada',
        }
    }

    handleModo = (checked) => {
        let {status} = this.props;
        let {itens} = this.state;
        if (!checked) {
            itens.sort((a, b) => (a.pontos[status===1 ? "campeonato":"campeonato_parcial"] > b.pontos[status===1 ? "campeonato":"campeonato_parcial"]) ? -1 : 1);
            this.setState({modo: 'campeonato', itens});
        } else {
            itens.sort((a, b) => (a.pontos[status===1 ? "rodada":"rodada_parcial"] > b.pontos[status===1 ? "rodada":"rodada_parcial"]) ? -1 : 1);
            this.setState({modo: 'rodada', itens});
        }
    };


    handleSearch = (query) => {
        this.setState({isLoading: true});

        getSearchLigas({q: query})
            .then(({data}) => {
                const options = data.map((i) => ({
                    nome: i.nome,
                    id: i.liga_id,
                    slug: i.slug,
                    imagem: i.imagem,
                    moderada: i.tipo == 'Moderada' ? true : false,
                    sem_capitao: i.sem_capitao,
                    mata_mata: i.mata_mata
                }));

                this.setState({options});
                this.setState({isLoading: false});
            });
    };

    handleChange = (itens) => {
        const liga = itens[0];
        //this.setState({liga});
        if (liga) {
            trackPromise(this.props.fetchParcial({q: liga.slug}));
        }
    };

    getObjectItem = (item, index) => {
        const { status } = this.props;
        item.ranking[status===1 ?this.state.modo:this.state.modo+"_parcial"] = index+1;
        return (
            <ClubItem  club={item} modo={status===1 ?this.state.modo:this.state.modo+"_parcial"} total={item.total}/>
        );
    };

    getTitleList = () => {
        const {liga, imagem, rodada_id} = this.state;
        return (
            <Row className="pl-1 pr-1 d-flex justify-content-between">

                    <span className="glyphicon small">
                            <img alt="..." src={liga ? imagem : require("assets/img/escudo/sprite_escudos.png")}/>
                    </span>

                    <span className="h2 text-white">{liga ? liga.nome : ""} #{rodada_id} </span>


                    <span className="text-responsive h3 mb-0 text-md flex-row-reverse align-content-end">
                        {this.state.modo=='campeonato' &&<Badge className="bg-white text-gray-dark posicao">VR</Badge>}
                        {this.state.modo!='campeonato' &&<Badge className="bg-white text-gray-dark posicao">JG</Badge>}
                    <Badge className="bg-white text-gray-dark posicao">PTS</Badge>
                    </span>
            </Row>
        );
    };

    getHelpItem = () => {
        return (
            <BootstrapSwitchButton
                id="modo-checkbox"
                checked={this.state.modo == 'rodada'}
                onlabel='Rodada'
                onstyle='info'
                offlabel='Campeonato'
                offstyle='success'
                width='120'
                height='45'
                reversed
                className='form-control-plaintext col-md-9 text-responsive'
                name="rodada-checkbox"
                onChange={this.handleModo}
            />
        )
    };
    getHeaderList = () => {
        const {isLoading, options} = this.state;
        return (
            <>
                <Row>
                    <Col lg="12">
                        <AsyncTypeahead
                            id="async-example"
                            searchText={"Buscando no CartolaFC..."}
                            isLoading={isLoading}
                            labelKey="nome"
                            minLength={3}
                            onSearch={this.handleSearch}
                            onChange={this.handleChange}
                            options={options}
                            useCache={true}
                            promptText={"Digite para pesquisar..."}
                            emptyLabel={"Nenhuma liga encontrada..."}
                            placeholder="Buscar ligas no CartolaFC..."
                            renderMenuItemChildren={(option, props) => (
                                <React.Fragment key={option.id}>
                                    <img
                                        alt={option.slug}
                                        src={option.imagem}
                                        style={{
                                            height: '24px',
                                            marginRight: '10px',
                                            width: '24px',
                                        }}
                                    />
                                    <span>{option.nome}</span>
                                    {option.moderada}
                                </React.Fragment>
                            )}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    getFooterList = () => {
        return (
            <>
                Clique em cima <i className="fa fa-hand-pointer"></i> do Time para ver detalhes,
                <Badge className="bg-default text-white small posicao">nº</Badge>=Número que indica a classificação no
                campeonato,
                <Badge className="bg-primary text-white small posicao">nº</Badge>=Número que indica prioridade para
                apostar
            </>
        );
    };

    toggleModal = (e, state, id=null) => {
        e.preventDefault();
        const { liga } = this.props;

        this.setState({
          [state]: !this.state[state], itemSelect: id
        });
    };

    getHeaderModal = () => {
        const { liga, status } = this.props;
        if(liga && this.state.itemSelect){
            const item = liga.times[this.state.itemSelect];
            return (<ClubItem  club={item} modo={status===1 ?this.state.modo:this.state.modo+"_parcial"} total={item.total}/>);
        }
    };

    getItemModal = () => {
        const { liga, times_mercado, partidas, rodada_id, atletas, status } = this.props;
        if(liga && this.state.itemSelect) {
            const jid_list = liga.times[this.state.itemSelect].jogadores;
            let j_list =[];

            if(atletas){
                for(let jid of jid_list.atletas){
                    if(atletas[jid]){
                        let j = atletas[jid];
                        if(atletas[jid]){
                            j['pontos'] = atletas[jid].pontos;
                        }else{
                            j['pontos'] = '-';
                        }

                        j_list.push(j);
                    }
                }
            }

            return (
                <>
                    {(liga && this.state.itemSelect)  &&
                    <PlayersList jogadores={j_list} times={times_mercado} capitao={!liga.sem_capitao?jid_list.capitao:0}
                                 partidas={partidas} rodada={rodada_id} parcial={status===2} />}
                </>
            );
        }
    };

    async componentDidMount() {
        const {liga, status, atletas, rodada_id} = this.props;
        this.setState({rodada_id: this.props.rodada_id});
        if (liga) {
            const imagem = liga.url_flamula_png ? liga.url_flamula_png : liga.url_trofeu_png;

            let itens = this.timesPontuacao(status, liga, atletas);

            this.setState({liga: liga, itens, imagem});

            if(rodada_id!=liga.rodada || (status!=4 && status!=liga.status)){
                trackPromise(this.props.fetchParcial({q: liga.slug}));
            }
        }
    };

    timesPontuacao(status, liga, atletas) {
        let itens = [];
        if (status != 2) {
            for (let t in liga.times) {
                let time = liga.times[t];
                time['total'] = 12;
                itens.push(time);
            }
        } else {
            for (let t in liga.times) {
                let time = liga.times[t];

                if (atletas && time.jogadores) {
                    let pts = 0;
                    let ttl = 0;
                    for (let jid of time.jogadores.atletas) {
                        if (atletas[jid]) {
                            let j = atletas[jid];
                                if(j.pontos){
                                    if (j.posicao_id == 6 && j.pontos > 0) {
                                        ttl += 1;
                                    } else if (j.posicao_id != 6) {
                                        ttl += 1;
                                    }
                                    if (!liga.sem_capitao) {
                                        if (jid == time.jogadores.capitao) {
                                            pts += j.pontos;
                                        }
                                    }
                                    pts += j.pontos;
                                }
                        }
                    }
                    time['pontos']['rodada_parcial'] = pts;
                    time['pontos']['campeonato_parcial'] = time['pontos']['campeonato'] + pts;
                    time['total'] = ttl;
                }
                itens.push(time);
            }
        }

        itens.sort((a, b) => (a.pontos[status===1 ?this.state.modo:this.state.modo+"_parcial"] > b.pontos[status===1 ?this.state.modo:this.state.modo+"_parcial"]) ? -1 : 1);
        return itens;
    }

    componentDidUpdate(prevProps) {
        const {liga, status, atletas} = this.props;

        if (liga) {
            if (prevProps.liga !== liga) {
                const imagem = liga.url_flamula_png ? liga.url_flamula_png : liga.url_trofeu_png;

                let itens = this.timesPontuacao(status, liga, atletas);
                this.setState({liga: liga, itens, imagem});
            }
        }
    }

    render() {
        const {status} = this.props;
        return (
            <>
                {status!=4 && super.render()}
                {status==4 &&
                    <>
                    <Card className="shadow">
                        <CardBody className="border-0 mx-auto">

                            <h1>Manutenção</h1>
                                <div>O mercado está em manutenção, volte mais tarde!</div>
                        </CardBody>
                    </Card>
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

                        </>

                }
            </>
        );
    }
}

function mapStateToProps(state) {
    const {mercado} = state.loadMercado;
    const {liga} = state.parcialMachine;
    return {
        liga,
        rodada_id: mercado ? mercado.rodada.codigo : '',
        status: mercado ? mercado.status : 0,
        times_mercado: mercado ? mercado.times : {},
        partidas: mercado? mercado.partidas:[],
        atletas: mercado? mercado.atletas:[],
    };
}

const actionCreatorsToProps = {
    fetchParcial: cartolaActions.fetchParcial,
    fetchPontuacao: cartolaActions.fetchParcial
};

export default connect(mapStateToProps, actionCreatorsToProps)(PartialCont);
