import React, {Component} from 'react';
import {Button, Modal} from "reactstrap";
import html2canvas from "html2canvas";
import {cartolaService} from "../services";
import {toast} from "react-toastify";
import {api_urls} from "../api";


class ShareButton extends Component {
    constructor() {
        super();

        this.state = {
            isOpen: false,
            encodedImage: '',
            shareLink:''
        }
    }

    handleClick = (e) => {
        const {idElement, msgm} = this.props;

        this.setState({isOpen: true});

        this.toCanvas(idElement, msgm);
    };

    toCanvas(idElement, msgm) {
        if(!msgm) msgm = "Compartilhar...";
        const element = document.querySelector(idElement);
        //var altura = parseInt(document.body.height);
        //console.log(altura);
        element.style.top = 0;
        element.style.left = 0;
        element.style.position = "fixed";
        var thisObj = this;

        html2canvas(element,{logging: false, proxy: api_urls.proxy}).then(
            function (canvas) {
                element.style.position = "relative";
                var image = canvas.toDataURL("image/png");
                var encodedPng = image.substring(image.indexOf(',') + 1, image.length);

                cartolaService.saveCompartilhar(msgm, encodedPng).then(
                    ({data}) => {
                        let image = 'data:image/png;base64,'+encodedPng;
                        thisObj.setState({shareLink: window.location.origin+data.link, encodedImage: image});
                        // addthis_share = {
                        //            url: window.location.origin+msg,
                        //            title: mensagem
                        // }
                    },
                    error => {
                        toast.error("Não foi possível compartilhar!");
                        this.setState({isOpen: false});
                    }
                    );
            });
    };

    toggleModal = (e) => {
        e.preventDefault();
        this.setState({
          isOpen: !this.state.isOpen
        });
    };

    render() {
        return (
            <>
                <button type="button" id="update" className="btn btn-primary" onClick={this.handleClick} {...this.props}>
                    <span className="d-inline-flex">
                    <i className="fa fa-share"></i><span className="d-none d-sm-block"> Compartilhar</span>
                    </span>
                </button>

                <Modal
                    className="modal-dialog-centered modal-lg"
                    isOpen={this.state.isOpen}
                    toggle={(e) => this.toggleModal(e)}>
                    <div className="modal-header">
                        {/*<button type="button" className="close" data-dismiss="modal"*/}
                        {/*        aria-label="Close">*/}
                        {/*    <span aria-hidden="true">&times;</span>*/}
                        {/*</button>*/}
                        <div className="btn-group btn-group-justified" role="group" aria-label="...">

                            <div className="btn-group" role="group">
                                <span style={{display:"inline-flex"}}>
									  		<h4>Compartilhar: </h4>
											<div className="addthis_sharing_toolbox text-center"></div>
										</span>

                            </div>
                        </div>
                        <div id="dadosCompartilharHeader" className="compartilhado">
                        </div>
                    </div>
                    <div className="modal-body ativa-scroll">
                        <div id="dadosCompartilhar" className="compartilhado">
                            {!this.state.encodedImage && <h2>Aguarde....</h2>}
                            {this.state.encodedImage && <img src={this.state.encodedImage} />}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="download_img" download="maquinacfc.png" href={this.state.encodedImage} aria-disabled={!this.state.encodedImage} className="btn btn-primary"><span
                                className="fa fa-download"></span>
                                <span className="hidden-xs"> Download</span></a>
                        <a href="#" id="linkShare" href={this.state.shareLink} aria-disabled={!this.state.shareLink} className="btn btn-primary"><span
                            className="fa fa-link"></span><span className="hidden-xs"> Link</span></a>
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            onClick={(e) => this.toggleModal(e)}
                        >
                            Fechar
                        </Button>
                        {/*<Button color="primary" type="button">*/}
                        {/*    Compartilhar*/}
                        {/*</Button>*/}
                    </div>
                </Modal>
            </>
        );
    }
}

ShareButton.propTypes = {};

export default ShareButton;
