import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Col, ListGroup, ListGroupItem, Media, Row} from "reactstrap";
import JogadorItem from "./JogadorItem";
import {utils} from "../helpers";

const PlayersList = props => {
    const { jogadores, times, capitao, partidas, parcial } = props;

    utils.ordenaJogadores(jogadores);

    return (
        <ListGroup>
            {
                jogadores.map((jogador, index) => (
                    <ListGroupItem key={'j'+index} className="p-lg-3 p-md-3 p-xs-1 container-fluid"  tag="a" href="#" action>
                        <JogadorItem item={jogador} times={times} partidas={partidas} capitao={capitao} parcial={parcial} />
                    </ListGroupItem>
                ))
            }
        </ListGroup>
    );
};

PlayersList.propTypes = {
    jogadores: PropTypes.array.isRequired,
    times: PropTypes.array.isRequired,
    capitao: PropTypes.number,
    rodada: PropTypes.string,
    partidas: PropTypes.array.isRequired
};

export default PlayersList;
