import React from "react";
// reactstrap components
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {trackPromise} from "react-promise-tracker";
import {getCompartilhar} from "../api";

class Share extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            msgm: '',
            image: ''
        }
    }

    async componentDidMount() {
        const {data} = await trackPromise(getCompartilhar({rodada: this.props.match.params.rodada, code: this.props.match.params.code}));
        this.setState({msgm: data.mensagem, image: data.imagem});
    };

    render() {
        const {msgm, image} = this.state;
        return (
            <>
                <Container fluid>
                    {/* Table */}
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <h1 className=" mb-0">{msgm}</h1>
                                </CardHeader>
                                <CardBody className={'text-center'}>
                                    <Row className=" align-items-center">
                                        <Col>
                                            <img src={image} style={{'max-width':'100%'}} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Share;
