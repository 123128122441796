import React from "react";
import { Redirect } from 'react-router';

// reactstrap components
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Modal
} from "reactstrap";
import {connect} from "react-redux";
import {userActions} from "../actions";
import {toast} from "react-toastify";
import {trackPromise} from "react-promise-tracker";

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: '',
                email: '',
                password: '',
                repassword: ''
            },
            submitted: false
        };
    }
    handleChange = event => {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    };

    handleSubmit =  event => {
        event.preventDefault();

        this.setState({ submitted: true });
        const { user } = this.state;
        console.log(user);
        if(!user.name){
            toast.error("Nome obrigatório");
        }
        if(!user.email){
            toast.error("Email obrigatório");
        }

        if (user.name && user.email && user.password && user.repassword) {
            if(user.password!==user.repassword){
                toast.error("Senhas não conferem!");
            }else{
                trackPromise(this.props.register(user));
            }
        }
    };

    render() {
        const { registering, register_ok } = this.props;
        const { user, submitted } = this.state;
        return (
            <>
                <Form role="form" onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-user-alt"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Nome" type="text" name="name" value={user.name} onChange={this.handleChange} required />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-email-83"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Email" type="email" autoComplete="new-email"
                                   name="email" value={user.email} onChange={this.handleChange} required />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-lock-circle-open"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Senha" type="password" autoComplete="new-password"
                                   name="password" value={user.password} onChange={this.handleChange} required />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-lock-circle-open"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Repetir Senha" type="password" autoComplete="new-password"
                                    name="repassword" value={user.repassword} onChange={this.handleChange} required />
                        </InputGroup>
                    </FormGroup>
                    <Row className="my-4">
                        <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="customCheckRegister"
                                    type="checkbox"
                                    name="agree"
                                    required
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="customCheckRegister"
                                >
                      <span className="text-muted">
                        Eu concordo com{" "}
                          <a href="#bie" target="_blank" onClick={e => e.preventDefault()}>
                          Política de Privacidade
                        </a>
                      </span>
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button className="mt-4" color="primary" type="submit">
                            Criar conta
                        </Button>
                    </div>
                    { register_ok && <Redirect to="/auth/login" />}
                </Form>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { registering, register_ok } = state.registration;
    return { registering, register_ok };
};

const actionCreatorsToProps = {
    register: userActions.register
};

const connectedRegisterForm = connect(mapStateToProps, actionCreatorsToProps)(RegisterForm);
export { connectedRegisterForm as RegisterForm };
