import React from "react";
// reactstrap components
import {Badge, Button, Col, Input, Row} from "reactstrap";
import {Redirect} from 'react-router';
// core components
import ObjectList from "../components/ObjectList";
import {connect} from "react-redux";
import JogadorItem from "../components/JogadorItem";
import {cartolaActions} from "../actions";
import {utils} from "../helpers";
import {trackPromise} from "react-promise-tracker";
import {cartolaService} from "../services";
import JogadorDetail from "../components/JogadorDetail";
import ModalHelp from "../components/Modals/ModalHelp";
import ModalDetail from "../components/Modals/ModalDetail";
import {putSalvarCartola} from "../api";
import {toast} from "react-toastify";

class TeamCont extends ObjectList {
    constructor(props) {
        super(props);
        this.state = {
            time: null,
            itens: [],
            title: 'Seleção da Rodada',
            isOpenSalvar: false,
            login: '',
            passwd: ''
        }
    }

    onChangeInput = event => {
        event.preventDefault();
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    getObjectItem = (item, index) => {
        const {times, time, partidas} = this.props;
        return (
            <JogadorItem id={index} item={item} capitao={time.capita} partidas={partidas} times={times}/>
        );
    };

    getHelpItem = () => {
        return (
            <h1><Badge className="bg-default text-white">{this.props.time && this.props.time.formacao}</Badge></h1>
        )
    };

    getTitleList = () => {
        const {rodada_id} = this.props;
        return (
            <Row className="pl-2 pr-2 ml-1">
                <div>
                    <span className="h2 text-white">Jogador #{rodada_id} </span>
                </div>
                <div className={"ml-auto mr-3"}>
                        <Badge className="text-gray-dark mr-1">ULT</Badge>
                        <Badge
                            className="text-gray-dark mr-1">* {this.props.time && utils.printFloat(this.props.time.total_pontos)}</Badge>
                        <Badge
                            className="text-gray-dark mr-1">C$ {this.props.time && utils.printFloat(this.props.time.total_cartoletas)}</Badge>

                </div>
            </Row>
        );
    };

    getHeaderList = () => {
        return (
            <h2 className="mb-0 text-responsive "><span className="glyphicon">
                <img alt="..." src={require("assets/img/escudo/sprite_escudos.png")}/></span> Seleção
                #Rodada {this.state.rodada_id}
            </h2>
        );
    };

    getFooterList = () => {
        return (
            <>
                <i className="fa fa-hand-pointer"></i> Clique em cima do Jogador para detalhes, ULT= Pontuação na última
                rodada, * = Média do Jogador, $ = Preço em cartoletas
            </>
        );
    };

    toggleModalSalvar = (e) => {
        e.preventDefault();
        this.setState({
            isOpenSalvar: !this.state.isOpenSalvar
        });
    };

    toggleSalvarGlobo = async (e) => {
        e.preventDefault();
        if (this.state.passwd && this.state.login) {
            toast.info("Aguarde que estamos resolvendo captcha de login da Globo para salvar seu time",{autoClose: 60000});
            await trackPromise(putSalvarCartola({time: this.props.time, config: this.props.config, login: this.state.login, senha: this.state.passwd})).then(
                ({data}) => {
                    toast.dismiss()
                    if(data.mensagem[0]){
                        toast.success(data.mensagem[1]);
                    }else{
                        toast.error(data.mensagem[1]);
                    }
                },
                error => {
                    toast.dismiss()
                    toast.error(utils.handleError(error));
                }
            )
        } else {
            toast.error("Login e senha devem ser preenchidos !");
        }

    };

    toggleModal = async (e, state, id = null) => {
        e.preventDefault();
        const {rodada_id} = this.props;
        const {itens} = this.state;
        const jid = id;
        let item;
        let titleModal;

        for (let j of itens) {
            if (j.atleta_id === jid) {
                item = j;
                break;
            }
        }

        const {data} = await trackPromise(cartolaService.jogadorStats(id));
        let dataTranspose = null;

        if (jid) {
            titleModal = "Desempenho do Jogador: " + item.apelido + " #" + rodada_id;
        }


        if (!utils.isEmpty(data)) {
            dataTranspose = data[0].map((_, colIndex) => data.map(row => row[colIndex]));
        }

        this.setState({
            [state]: !this.state[state], itemSelect: id, jogadorData: dataTranspose, titleModal: titleModal
        });
    };


    getItemModal = () => {
        const {times, partidas} = this.props;
        const {itens, itemSelect, jogadorData} = this.state;
        const jid = itemSelect;
        let item = null;

        for (let j of itens) {
            if (j.atleta_id === jid) {
                item = j;
                break;
            }
        }

        if (jogadorData) {
            return <JogadorDetail data={jogadorData} item={item} times={times} partidas={partidas}/>
        }
    };

    async componentDidMount() {
        if (this.props.time) {
            this.setState({itens: this.props.time.jogadores, rodada_id: this.props.rodada_id});
        } else {
            this.setState({rodada_id: this.props.rodada_id});
        }
    };

    componentDidUpdate(prevProps) {

        if (this.props.time) {
            const itens = this.props.time.jogadores;
            if (prevProps.time !== this.props.time) {
                if (itens) {
                    this.setState({itens: this.props.time.jogadores});
                }
            }
        }
    }

    back = (e) => {
        this.props.resetEscalacao();
    };

    buttonLeft = () => {
        return (<button type="button" id="limpar" className="btn btn-primary" onClick={this.back} disabled={!this.props.redirect}>
                    <span className="d-inline-flex">
                    <i className="fa fa-backward"></i><span
                        className="d-none d-sm-block"> Voltar</span>
                    </span>
        </button>);
    };

    buttonRight = () => {
        return (<>
            <button type="button" id="salvar" className="btn btn-primary" disabled={!this.props.time}
                    onClick={(e) => this.toggleModalSalvar(e)} data-dismiss="modal">
                    <span className="d-inline-flex">
                    <i className="fa fa-save"></i><span
                        className="d-none d-sm-block"> Salvar</span>
                    </span>
            </button>
            <ModalDetail state="isOpenSalvar" title="Salvar Time no CartolaFC" isOpen={this.state.isOpenSalvar}
                         toggleModal={this.toggleModalSalvar}
                         notShare={true}
                         getHeader={() => (
                             <h4 className="modal-title"><img src={require('assets/img/logo-globo.png')}/></h4>)}
                         extraButtons={() => (<button type="button" id="salvar" className="btn btn-primary"
                                                      onClick={(e) => this.toggleSalvarGlobo(e)}>
                                    <span className="d-inline-flex">
                                    <i className="fa fa-save"></i><span
                                        className="d-none d-sm-block"> Salvar</span>
                                    </span>
                         </button>)}>
                <hr className="p-0 m-0"/>
                <Row>
                    <Col>

                        <div className="form-group">
                            <label htmlFor="login-passaporte" className="control-label">Email:</label>
                            <input type="email"
                                   value={this.state.login}
                                   onChange={this.onChangeInput}
                                   required={true}
                                   className="form-control" id="login-passaporte"
                                   name="login"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message-text" className="control-label">Senha:</label>
                            <input type="password" className="form-control"
                                   value={this.state.passwd}
                                   required={true}
                                   onChange={this.onChangeInput}
                                   id="senha-passaporte" name="passwd"/>
                        </div>

                    </Col>
                </Row>
            </ModalDetail>
        </>);
    };

    render() {
        return (
            <>
                {!this.props.time && this.props.redirect && <Redirect to="/escalar"/>}
                {this.props.time && super.render()}
            </>
        );
    }
}

function mapStateToProps(state) {
    const {mercado} = state.loadMercado;

    return {
        rodada_id: mercado ? mercado.rodada.codigo : '',
        times: mercado ? mercado.times : {},
        partidas: mercado ? mercado.partidas : []
    };
}

const actionCreatorsToProps = {
    resetEscalacao: cartolaActions.resetEscalacao
};

export default connect(mapStateToProps, actionCreatorsToProps)(TeamCont);
