import {createActions, createReducer} from 'reduxsauce'
import {indexdb, store} from "../helpers";
import {cartolaActions} from "../actions";

indexdb.get('liga').then( liga => {
    if(liga){
        store.dispatch(ParcialCreators.parcialSuccess(liga));
    }
});

let liga = null;

const INITIAL_STATE = liga ? {liga, searching: false, error: false} : {searching: false, error: false};

const {Types, Creators} = createActions({
    parcialRequest: ['config'],
    parcialSuccess: ['liga'],
    parcialFailure: null
});


function parcialRequest(state = INITIAL_STATE, action) {
    return {...state, config: action.config, searching: true}
}

function parcialSuccess(state = INITIAL_STATE, action) {
    return {...state, liga: action.liga, searching: false}
}

function parcialFailure(state = INITIAL_STATE, action) {
    return {...state, error: true, searching: false}
}

const HANDLERS = {
    [Types.PARCIAL_REQUEST]: parcialRequest,
    [Types.PARCIAL_SUCCESS]: parcialSuccess,
    [Types.PARCIAL_FAILURE]: parcialFailure,
};

export const ParcialCreators = Creators;

export const parcialReducer = createReducer(INITIAL_STATE,HANDLERS);
