import React from "react";
import DateCountdown from 'react-date-countdown-timer';

class Datedown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date_over: false
        }
    }
    componentDidMount() {
        this.setState({date_over: false})
    }

    closeDate(){
        this.setState({date_over: true})
    }

    render (){
        return (
            <>
            {!this.state.date_over &&
                    <DateCountdown callback={()=>this.closeDate()} dateTo={this.props.date_close} locales={['ano', 'mês', 'dia', 'h', 'min', 's']}
                       locales_plural={['anos', 'meses', 'dias', 'h', 'min', 's']}/>}
               {this.state.date_over && this.props.label_close}
           </>
        )
    }
};

export default Datedown;
