import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import "assets/css/bootstrap-slider.min.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/cartola.css";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "layouts/Admin.js";
import {Provider} from "react-redux";
import {indexdb, store} from "./helpers";
import LoadingIndicator from "components/Loading";
import {trackPromise} from "react-promise-tracker";
import {cartolaActions} from "./actions";
import {PontuacaoCreators} from "./reducers/pontuacao.reducer";

var tt = setInterval(async () => {
    store.dispatch(cartolaActions.fetchMercado())
}, 60000);

var tt2 = setInterval(async () => {
    const mercado = store.getState().loadMercado.mercado;
    if(mercado && mercado.status==2){
        store.dispatch(cartolaActions.fetchPontuacao())
    }
}, 30000);

trackPromise(indexdb.get('mercado').then( result => {
    if(result){
        store.dispatch(cartolaActions.fetchMercadoSuccess(result))
        indexdb.get('pontuacao').then( pontuacao => {
            if(pontuacao){
                store.dispatch(PontuacaoCreators.success(result))
            }
        });
    }
    store.dispatch(cartolaActions.fetchMercado())
}));
//trackPromise(store.dispatch(cartolaActions.fetchMercado()));
//trackPromise(store.dispatch(cartolaActions.fetchMercado()));
store.dispatch(cartolaActions.fetchPontuacao());

ReactDOM.render(
    <Provider store={store}>
        <LoadingIndicator />
        <BrowserRouter>
            <Switch>
                {/*<Route path="/app" render={props => <AuthLayout {...props} />}/>*/}
                <Route path="/" render={props => <AdminLayout {...props} />}/>
                <Redirect from="/" to="/index"/>
            </Switch>
        </BrowserRouter>
        <ToastContainer autoClose={5000} />
    </Provider>,
    document.getElementById("root")
);
