import {userConstants} from '../constants';
import {userService} from '../services/user.service'
import {toast} from "react-toastify";
import {indexdb, utils} from "../helpers";

export const userActions = {
    register,
    login,
    logout,
    loginSocial,
    success
};

function register(user) {
    function request() { return { type: userConstants.REGISTER_REQUEST} }
    function success() { return { type: userConstants.REGISTER_SUCCESS} }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE } }

    return async dispatch => {
        dispatch(request(user));

        let user_api = {
                email: user.email,
                username: user.email,
                first_name: user.name.split(' ')[0].trim(),
                last_name: user.name.substring(user.name.split(' ')[0].length).trim(),
                password: user.password
            };

        await userService.register(user_api)
            .then(
                ({ data }) => {
                    dispatch(success());
                    toast.success('Registrado com sucesso! \n Foi enviado um email para ativar seu cadastro.');
                },
                error => {
                    dispatch(failure(error));
                    toast.error(utils.handleError(error));
                }
            );
    };
}

function request(auth) { return { type: userConstants.LOGIN_REQUEST, auth } }
function success(auth) { return { type: userConstants.LOGIN_SUCCESS, auth } }
function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

function login(username, password) {
    return async dispatch => {
        dispatch(request({ username }));
        await userService.login(username, password)
            .then(
                ({ data }) => {
                    const auth = data;
                    indexdb.set('auth', auth);
                    dispatch(success(auth));
                },
                error => {
                    dispatch(failure(error));
                    toast.error(utils.handleError(error));
                }
            );
    };
}

function loginSocial(access_token, provider) {
    return async dispatch => {
        dispatch(request({ access_token}));
        await userService.loginSocial(access_token, provider)
            .then(
                ({ data }) => {
                    const auth = data;
                    indexdb.set('auth',auth);
                    dispatch(success(auth));
                },
                error => {
                    dispatch(failure(error));
                    toast.error(utils.handleError(error));
                }
            );
    };
}

function logout() {
    userService.logout();
    return {type: userConstants.LOGOUT};
}

