import React, {Component} from "react";
// reactstrap components
import {Container} from "reactstrap";
// core components
import ResultsCont from "../containers/ResultsCont";
import Header from "../components/Headers/Header";


export default class Results extends Component {
  render() {
    return (
      <>
        <Header title="Classificação" subtitle="asdasdas" gradient="info" />
        {/* Page content */}
        <Container className="mt--7">
          {/* List */}
          <ResultsCont />
        </Container>
      </>
    );
  }
}
