import React from "react";
// reactstrap components
import {Card, CardBody, CardHeader, Container, Row, Col, Button} from "reactstrap";
import Header from "../components/Headers/Header";
import PayPalBtn from '../components/PayPalBtn'
import {trackPromise} from "react-promise-tracker";
import {getOrder} from "../api";
import {connect} from "react-redux";

class Plans extends React.Component {
    constructor() {
        super();
        this.state ={
            custom_id:null
        }
    }

    paypalSubscribe = (data, actions) => {
        if(this.state.custom_id){
            return actions.subscription.create({
                'plan_id': "P-16290387VA624481XL6QEUPY",
                'custom_id': this.state.custom_id
            });
        }
    };
    paypalOnError = (err) => {
        console.log("Error");
    }
    paypalOnApprove = (data, detail) => {
        console.log("Paypal approved");
        console.log(data.subscriptionID);
        //const {data} = trackPromise(getOrder());
        //this.setState({custom_id: data.custom_id});
    };

    async componentDidMount() {
        const {data} = await trackPromise(getOrder());
        this.setState({custom_id: data.custom_id});
    };

    render() {
        const {user} = this.props;

        return (
            <>
                <Header title="Planos" subtitle="" gradient="info"/>
                <Container className="mt--7 pb-5">
                    <Row className="justify-content-center">
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <h1 className=" mb-0">Assinatura</h1>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                        <h3>
                                            Seja membro PRO da Máquina do Cartola e tenha acesso ilimitado a todas as funcionalidades da Máquina do Cartola
                                        </h3>
                                            <ul>
                                                <li>Escalação</li>
                                                <li>Olheiro</li>
                                                <li>Estatísticas</li>
                                                <li>Seleção da Rodada</li>
                                                <li>Banco de reserva</li>
                                                <li>Dicas das Rodada</li>
                                                <li>Novas funcionalidades</li>
                                            </ul>

                                            <h5>*Experimente 7 dias gratuitamente</h5>
                                            <h5>*Cancele a qualquer momento</h5>
                                            <h5>*Por apenas R$ 2,99 mensais</h5>

                                        {user.role_name!='bronze' &&
                                            <PayPalBtn
                                                amount="2,99"
                                                currency="BRL"
                                                createSubscription={this.paypalSubscribe}
                                                onApprove={this.paypalOnApprove}
                                                catchError={this.paypalOnError}
                                                onError={this.paypalOnError}
                                                onCancel={this.paypalOnError}
                                            />
                                        }
                                        {user.role_name == 'bronze' &&
                                            <Button  className="btn-danger">
                                                Cancelar Assinatura
                                            </Button>
                                        }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { logged, auth } = state.authentication;
    return {
            logged,
            user: auth.user,
        };
}

export default connect(mapStateToProps)(Plans);
